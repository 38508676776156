.app-footer {
  padding: var(--page-padding);
  border-top: 1px solid var(--login-footer-border-color);
  background-color: var(--base-bg);
  transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out, border 0.25s ease-in-out;
  text-align: left;

  .container-fluid {
    max-width: 100%;
  }

  .app-footer__container {
    .login-footer__item + .login-footer__item {
      margin-top: 1.8rem;
    }

    .login-footer__item--teaser + .login-footer__item {
      margin-top: 1.2rem;
    }
  }

  .login-footer__list-item--phone {
    margin-left: auto;

    .login-footer__link:before {
      display: none;
    }
  }
}

.login-footer {
  font-size: 1.2rem;

  p {
    margin: 0;
    line-height: 1.22;
  }

  &__item {
    & + & {
      margin-top: 2rem;
    }

    &--teaser {
      color: #858585;
    }
  }

  &__link {
    display: flex;
    margin-top: .5rem;
    color: var(--login-footer-link-color);
    transition: color .25s ease-in-out;

    &-description {
      text-decoration: underline;
    }

    &-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 1.4rem;
      margin-right: .5rem;

      svg {
        width: 100%;
      }
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;

    @media all and (max-width: 450px) {
      justify-content: center;
    }
  }
}

@media (max-width: 992px) {
  .login-footer__list + .login-footer__list {
    margin-top: 1.5rem;
  }
}

@media (min-width: 576px) {
  .login-footer__list-item + .login-footer__list-item {
    margin-left: 2rem;
  }
}
