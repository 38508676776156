.bank-detail-modal {
  .invoice-modal__action-submit {
    background: var(--modal-header-notify-bg);

    &[disabled] {
      opacity: .6;
      cursor: auto;
    }

    &:hover:not([disabled]) {
      background: var(--modal-header-notify-bg-hover);
    }
  }
}
