.multi-select {
  position: relative;
  border: 1px solid var(--page-content-meta-input);
  border-radius: 3px;

  &__trigger {
    position: relative;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border: none;
    height: 4rem;
    width: 100%;
    background: none;
    outline: none;
    text-align: left;
    padding: 0 4.5rem 0 1rem;
    color: var(--page-content-input-color);
    cursor: pointer;

    &--selected-option {
      &:not(:last-of-type):after {
        content: ",";
        padding-right: 3px;
      }
    }

    &-clear-all {
      position: absolute;
      top: 0;
      right: 3rem;
      width: 3rem;
      height: 4rem;
      color: var(--btn-error-bg);

      svg {
        width: 1.4rem;
        fill: currentColor;
      }
    }

    &-icon {
      position: absolute;
      width: 3rem;
      right: 0;
      color: #4079ad;
      text-align: center;
      transform: rotate(0);
      transition: transform 0.25s ease-in-out;
    }

    &--collapsed {
      .multi-select__trigger-icon {
        transform: rotate(-180deg);
      }
    }
  }

  &--disabled {
    opacity: 0.7;

    .multi-select__trigger {
      color: var(--editor-toolbar-button-color-disabled) !important;
      cursor: auto;
    }
  }

  &__options {
    position: absolute;
    border: 1px solid rgba(0, 0, 0, 0);
    border-top: none;
    border-radius: 3px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    top: 4rem;
    left: 1rem;
    z-index: 10;
    width: 20rem;
    max-height: 0;
    overflow: auto;
    opacity: 0;
    background: var(--modal-content-bg);
    transition: max-height 0.25s ease-in-out, border 0.25s ease-in-out,
      opacity 0.25s ease-in-out;

    &--collapsed {
      max-height: 20rem;
      opacity: 1;
      border: 1px solid var(--page-content-meta-input);
      border-top: none;
    }
  }

  &__option {
    display: block;
    width: 100%;
    border: none;
    text-align: left;
    background: var(--modal-content-bg);
    height: 3rem;
    color: #4079ad;
    font-size: 1.2rem;
    cursor: pointer;
    padding: 0 1rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;

    &:hover {
      background: var(--page-content-meta-input);
      color: #fff;
    }

    &--selected {
      background: var(--acm-form-selection);
      color: #fff;
    }
  }

  &__add {
    position: absolute;
    top: 0;
    left: 0;
    width: 4rem;
    height: 4rem;
    padding: 1rem;

    svg {
      width: 100%;
    }
  }

  &__selected-options {
    display: flex;
    flex-wrap: wrap;
  }

  &__selected-option {
    display: flex;
    white-space: nowrap;
    justify-content: center;
    align-items: center;

    svg {
      width: 1rem;
    }
  }
}

// .selected-options-hover {
//   position: absolute;
//   background-color: #fff;
//   border: 1px solid #ccc;
//   padding: 10px;
//   margin-top: 5px;
//   visibility: hidden;
//   opacity: 0;
//   transition: visibility 0s, opacity 0.5s ease-in-out;
//   z-index: 50000;
// }

// .selected-options-hover ul {
//   list-style: none;
//   padding: 0;
//   margin: 0;
// }

// .selected-options-hover li {
//   margin-bottom: 5px;
// }

// .selected-options-hover:hover {
//   visibility: visible;
//   opacity: 1;
// }

.tooltip-container {
  display: inline-block;
  position: relative;
  width: 100%;
}

.tooltip-trigger::after {
  content: attr(data-tooltip);
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px 10px;
  position: absolute;
  z-index: 1000;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s ease-in-out;
}

.tooltip-trigger:hover::after {
  visibility: visible;
  opacity: 1;
}
