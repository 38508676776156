.notification {
  position: relative;
  z-index: 48;
  display: flex;
  overflow: hidden;
  width: 35rem;
  max-width: 100%;
  padding: 0 2rem;
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity .25s ease-in-out, transform .25s ease-in-out;

  & + & {
    margin-top: 1rem;
  }

  &--success {
    background: var(--notification-success-bg);
  }

  &--warning {
    background: var(--notification-warning-bg);
  }

  &--error {
    background: var(--notification-error-bg);
  }

  &--info {
    background: var(--notification-info-bg);
  }

  p {
    margin: -1.5rem 0 2rem;
  }

  &__container {
    display: flex;
    overflow: hidden;
    max-height: 0;
    transition: max-height .25s ease-in-out;
  }

  &__close {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    padding: 0 1.5rem;
    width: 5rem;
    height: 6rem;
    color: var(--notification-close-icon-color);

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__content {
    color: var(--notification-content-color);
  }

  &__title {
    display: flex;
    font-size: 1.6rem;
    padding: 2.4rem 0 2.3rem;
    margin: 0;
    font-weight: normal;
    font-family: "open_sanssemibold", arial, SansSerif, serif;
    line-height: 1;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.6rem;
    height: 6rem;
    margin-right: 1rem;
    color: var(--notification-error-icon-color);

    svg {
      width: 100%;
    }
  }

  &__progress {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    height: 3px;
    width: 100%;
    background: var(--notification-progress-bar-bg);
    transform: translateX(-100%);
    animation: move-in 6s ease-in-out forwards;
    animation-play-state: running;
  }

  &:hover {
    .notification__progress {
      animation-play-state: paused;
    }
  }
}

@keyframes move-in {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

.animate-notification {
  &-enter {
    &.notification {
      opacity: 0;
      transform: translateX(100%);
    }

    .notification__container {
      max-height: 0;
    }
  }

  &-enter-active,
  &-enter-done {
    &.notification {
      opacity: 1;
      transform: translateX(0);
    }

    .notification__container {
      max-height: 10rem;
    }
  }

  &-exit {
    &.notification {
      opacity: 1;
      transform: translateX(0);
    }

    .notification__container {
      max-height: 10rem;
    }
  }

  &-exit-active {
    &.notification {
      opacity: 0;
      transform: translateX(100%);
    }

    .notification__container {
      max-height: 0;
      transition-delay: .15s;
    }
  }
}
