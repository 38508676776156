.slide-up-animation {
  &-enter {
    transform: translateY(1rem);
    opacity: 0;
  }

  &-enter-active,
  &-enter-done {
    transform: translateY(0);
    opacity: 1;
  }

  &-exit {
    transform: translateY(0);
    opacity: 1;
  }

  &-exit-active {
    transform: translateY(1rem);
    opacity: 0;
  }
}

.slide-in-out-animation {
  &-enter {
    transform: translateX(1rem);
    opacity: 0;
    visibility: hidden;
    transition: opacity .25s ease-in-out, visibility .25s ease-in-out, transform .25s ease-in-out;
  }

  &-enter-active,
  &-enter-done {
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
    transition: opacity .25s ease-in-out, visibility .25s ease-in-out, transform .25s ease-in-out;
  }

  &-exit {
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
    transition: opacity .25s ease-in-out, visibility .25s ease-in-out, transform .25s ease-in-out;
  }

  &-exit-active {
    transform: translateX(-1rem);
    opacity: 0;
    visibility: hidden;
    transition: opacity .25s ease-in-out, visibility .25s ease-in-out, transform .25s ease-in-out;
  }
}


.animated-max-width {
  transition: max-width .35s ease-in-out;
}


@keyframes move {
  0% {
    opacity: 1;
    left: -2rem;
  }

  25% {
    opacity: .5;
  }

  50% {
    opacity: 1;
  }

  75% {
    opacity: .5;
  }

  100% {
    opacity: 1;
    left: 120%;
  }
}
