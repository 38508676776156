.pagination {
  justify-content: center;
  margin-top: 4rem;

  li + li {
    margin-left: 1rem;
  }

  &__quick-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    padding: 1.65rem;
    width: 4rem;
    min-width: 4rem;
    height: 4rem;
    min-height: 4rem;
    background: var(--history-thead-background);
    opacity: 1;
    user-select: none;
    transition: background-color .25s ease-in-out, opacity .25s ease-in-out;

    &[disabled] {
      opacity: .6;
      pointer-events: none;
    }

    &:not([disabled]):hover {
      background: var(--history-thead-background-alt);
    }

    svg {
      width: 100%;
      fill: #fff;
    }
  }

  &__nav-item {
    display: flex;
    font-size: 1.4rem;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    padding: 1.6rem;
    width: 4rem;
    min-width: 4rem;
    height: 4rem;
    min-height: 4rem;
    color: var(--pagination-color);
    opacity: 1;
    user-select: none;
    transition: background-color .25s ease-in-out, opacity .25s ease-in-out, color .25s ease-in-out;

    &--active {
      background: var(--history-thead-background);
      pointer-events: none;
      color: var(--pagination-color-active);
    }

    &[disabled]:not(&--active) {
      opacity: .6;
      pointer-events: none;
    }

    &:not([disabled]):hover {
      background: var(--history-thead-background-alt);
      color: var(--pagination-color-active);
    }

    &--placeholder {
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 1;
      width: 4rem;
      min-width: 4rem;
      height: 4rem;
      min-height: 4rem;
    }
  }
}
