.history {
  min-height: calc(100vh - 23rem);
  padding: var(--page-padding);

  @media all and (max-width: 500px) {
    .currency-selectors {
      width: 100%;
    }
  }

  @media all and (max-width: 640px) {
    padding: var(--mobile-page-padding);
  }

  &__title {
    color: var(--page-header-voucher-page-color);
    margin: 0;

    @media all and (max-width: 500px) {
      font-size: 1.8rem;
    }

    &--subtitle {
      text-transform: uppercase;
      font-size: 1.5rem;
      margin-bottom: 0.5rem;
      letter-spacing: 1px;
      font-weight: bold;
      font-family: "open_sansbold", Arial, sans-serif;
      opacity: 0.4;
    }
  }

  &__search-form {
    margin-top: 3rem;

    ::placeholder {
      color: var(--history-search-input-placeholder-color);
    }
  }

  &__search-fields-container {
    position: relative;
    display: flex;
    margin-top: 3rem;

    @media all and (max-width: 500px) {
      margin-top: 2rem;
      padding-top: 5rem;
    }
  }

  .excel-btn {
    background: var(--checkbox-border-color-checked);
    color: white;
    margin-left: auto;
    transition: opacity 0.25s ease-in-out;
    opacity: 1;

    &:disabled {
      opacity: 0.5;
    }

    @media all and (max-width: 900px) {
      position: absolute;
      bottom: 100%;
      right: 0;
      margin-bottom: 2rem;
    }

    @media all and (max-width: 500px) {
      font-size: 1.4rem;
      padding: 0.9rem;
      margin-bottom: 0;
      margin-top: 0.5rem;
      bottom: auto;
      top: 0;
      right: 0;
    }
  }

  .adv-search {
    margin-left: 2rem;

    @media all and (max-width: 768px) {
      svg {
        margin-right: 0;
      }

      &__content {
        display: none;
      }
    }

    @media all and (max-width: 500px) {
      position: absolute;
      left: 0;
      top: 0;
      margin-left: 0;
      margin-top: -0.5rem;
    }
  }

  &__table {
    text-align: left;
    width: 100%;
  }

  &__thead {
    color: var(--history-thead-color);
    background: var(--history-thead-background);

    .btn {
      padding: 1rem;
      color: var(--history-thead-color);

      svg {
        transform: rotate(0);
        transition: transform 0.25s ease-in-out;
      }

      & .is-ascending {
        transform: rotate(-180deg);
      }
    }

    .history__cell:not(:first-of-type) {
      .btn {
        padding-left: 0;
      }
    }
  }

  &__cell {
    padding: 0.6rem 2rem;

    &--contact-name {
      .btn {
        margin-left: auto;
        padding: 0;
        color: #409b67;
      }
    }
  }

  &__table {
    .history__cell {
      &:first-of-type {
        max-width: 30rem;
      }

      &:not(:first-of-type) {
        text-align: right;

        .btn {
          padding-right: 0;
        }
      }
    }
  }

  &__tbody &__cell {
    padding: 1.6rem 2rem;
  }

  &__tbody &__tr:hover {
    background: var(--history-table-row-hover-background);
  }

  &__tbody &__tr:nth-child(even) {
    background: var(--history-even-table-row-background);
  }

  [data-cell-status] {
    display: inline-block;
    line-height: 1;
    padding: 0.7rem 1rem;
    background: transparent;
    border-radius: 5px;
    font-size: 1.2rem;
    letter-spacing: 0.05rem;
    color: var(--users-pending-color);
    font-family: "open_sanssemibold", arial, SansSerif, serif;
    text-transform: uppercase;
  }

  [data-cell-status="paid"] {
    background: var(--history-status-paid);
  }

  [data-cell-status="authorised"] {
    background: var(--history-status-in-progress);
  }

  [data-cell-status="hold for client payment"] {
    background: var(--history-status-on-hold);
  }

  [data-cell-status="hold"] {
    background: var(--history-status-on-hold);
  }

  [data-cell-status="unauthorised"] {
    background: var(--history-status-on-hold);
  }

  [data-cell-status="unauthorised (on suspense a/c)"] {
    background: var(--history-status-on-hold);
  }

  [data-cell-status="unauthorised (on suspense a/c) and held for client payment"] {
    background: var(--history-status-on-hold);
  }

  &__invoice-modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;

    &__backdrop {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.2);
      backdrop-filter: blur(2px);
    }

    &__body {
      background-color: white;
      padding: var(--page-padding);
      z-index: 3;
      font-size: 1.2rem;
      max-height: 80vh;
      overflow: auto;

      &-enter {
        transform: translateY(500px);
      }

      &-enter-active {
        transform: translateY(0);
        transition: transform 400ms;
      }

      &-enter-done {
        transform: translateY(0);
      }
    }

    img {
      max-width: 100%;
    }
  }
}

.history__loader {
  display: flex;
  justify-content: center;
  margin-top: 4rem;
}

.history-no-results {
  padding: 1rem 2rem;
}

.history-clear-date {
  position: absolute;
  z-index: 100;
  right: 0;
  bottom: 0;
  width: 4rem;
  height: 5rem;
  color: var(--btn-error-bg);
  transition: color 0.25s ease-in-out;

  &:hover {
    color: var(--btn-error-bg-alt);
  }

  svg {
    width: 1.1rem;
    fill: currentColor;
  }
}

.history-multiselect {
  width: 24rem;
  height: 5rem;

  @media all and (max-width: 1300px) {
    width: 25rem;
  }

  @media all and (max-width: 500px) {
    width: 100%;
  }

  .multi-select__trigger {
    height: 5rem;
  }

  .multi-select__options {
    top: 4.8rem;
  }

  .multi-select__trigger-clear-all {
    height: 5rem;
  }
}

.currency-selectors + .currency-selectors {
  position: relative;
  margin-left: 2.5rem;
  padding-left: 2.5rem;

  @media all and (max-width: 1300px) {
    padding-left: 0;
    margin-left: 0;
  }

  &:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 1rem;
    width: 1px;
    height: 3rem;
    background-color: rgba(128, 128, 128, 0.3);

    @media all and (max-width: 1300px) {
      display: none;
    }
  }
}

.adv-search-container {
  display: flex;
  align-items: flex-end;
  max-height: 0;
  opacity: 0;
  visibility: hidden;
  margin: 2rem 0 2.35rem;
  transition: max-height 0.25s ease-in-out, opacity 0.25s ease-in-out,
    visibility 0.15s ease-in-out;

  &--opened {
    opacity: 1;
    visibility: visible;
    max-height: 40rem;
  }
}

.history-clear-filters {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  margin-left: 2rem;
  height: 5rem;
  color: var(--base-color);
  padding: 1.6rem;
  line-height: 1;
  opacity: 0;
  visibility: hidden;
  transition: color 0.25s ease-in-out, opacity 0.25s ease-in-out,
    visibility 0.25s ease-in-out;

  @media all and (max-width: 768px) {
    margin-left: 0;
  }

  @media all and (max-width: 500px) {
    position: absolute;
    left: 5rem;
    top: 0;
    margin-top: -0.5rem;
  }

  &:hover {
    opacity: 0.7;
  }

  &--active {
    opacity: 1;
    visibility: visible;
  }

  svg {
    width: 1.8rem;
    color: var(--btn-error-bg);
    margin-right: 1rem;

    @media all and (max-width: 768px) {
      margin-right: 0;
    }
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 1rem;
    width: 1px;
    height: 3rem;
    background-color: rgba(128, 128, 128, 0.3);

    @media all and (max-width: 768px) {
      display: none;
    }
  }

  @media all and (max-width: 768px) {
    &__content {
      display: none;
    }
  }
}

.history-apply-filters {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  margin-left: 2rem;
  height: 5rem;
  color: var(--base-color);
  line-height: 1;
  padding: 0;
  opacity: 0;
  visibility: hidden;
  max-width: 0;
  max-height: 0;
  transition: color 0.25s ease-in-out, padding 0.25s ease-in-out,
    opacity 0.25s ease-in-out, visibility 0.25s ease-in-out,
    max-height 0.25s ease-in-out, max-width 0.25s ease-in-out;

  @media all and (max-width: 1300px) {
    margin-left: 0;
  }

  &:hover {
    opacity: 0.7;
  }

  &--active {
    opacity: 1;
    visibility: visible;
    max-height: 20rem;
    max-width: 20rem;
    padding: 1.6rem;
  }

  svg {
    width: 1.8rem;
    color: var(--profile-popup-background-color);
    margin-right: 1rem;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 1rem;
    width: 1px;
    height: 3rem;
    background-color: rgba(128, 128, 128, 0.3);

    @media all and (max-width: 1300px) {
      display: none;
    }
  }
}

.history__table--actions {
  &-checkbox {
    margin-top: 1rem;
    margin-right: 0;
    width: 2.5rem;

    &.input__label .input__checkbox-value:before {
      background: var(--base-bg);
    }

    &.input__label
      .input__element--checkbox:checked
      + .input__checkbox-value:before {
      background: var(--base-bg);
    }
  }
}

.history-sort-btn svg {
  margin-left: 1rem;
}

.history__table--actions-cell-checkbox {
  width: auto;
}

.mobile-table-head {
  display: none;
  align-items: center;
  padding: 0 2rem;
  color: var(--history-thead-color);
  background: var(--history-thead-background);

  .input--checkbox {
    display: block;
    margin-top: 0;
  }

  &__content {
    padding: 1.6rem 0 1.6rem 1rem;
  }
}

@media all and (max-width: 1300px) {
  .history {
    .adv-search-container {
      flex-wrap: wrap;
      gap: 2rem;

      &--opened {
        opacity: 1;
        visibility: visible;
        max-height: 100rem;
      }
    }
  }
}

@media all and (max-width: 1024px) {
  .history {
    .mobile-table-head {
      display: flex;
    }

    .history-no-results {
      padding-left: 0;
    }

    &__table,
    &__tbody,
    &__th,
    &__thead:not(.users__thead),
    &__tr {
      display: block;
    }

    .history__table .history__cell:first-of-type {
      max-width: 100%;
    }

    &__table {
      min-width: 100%;
    }

    &__thead:not(.users__thead) tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr:not([colspan="5"]) {
      overflow: hidden;
      max-height: 5.1rem;
      border: 1px solid var(--table-users-mobile-row-border-color);
      background: none !important;
      transition: max-height 0.45s ease-in-out !important;

      &:hover {
        background: none !important;
      }

      &.history__tr--collapsed {
        max-height: 60rem;

        td:first-of-type:before {
          transform: rotate(-135deg);
        }
      }
    }

    tr + tr {
      border-top: none;
    }

    td:not([colspan="5"]) {
      position: relative;
      display: flex;
      width: 100%;
      border: none;
      background: none !important;
    }

    td:not([colspan="5"]) {
      position: relative;
      display: flex;
      width: 100%;
      border: none;
      background: none !important;

      &:not([colspan="5"]):before {
        content: attr(data-cell-name);
        min-width: 10rem;
        max-width: 10rem;
        width: 10rem;
        text-align: left;
        font-family: "open_sanssemibold", Arial, sans-serif;
      }

      &:first-of-type:not([colspan="5"]) {
        cursor: pointer;

        &:before {
          content: "";
          position: absolute;
          right: 2rem;
          top: 1.7rem;
          width: 1rem;
          min-width: 1rem;
          max-width: 1rem;
          border-radius: 0;
          height: 1rem;
          padding: 0;
          border: 2px solid var(--table-users-mobile-chevron-color);
          border-top: none;
          border-left: none;
          transform: rotate(45deg);
          transition: transform 0.45s ease-in-out;
        }
      }

      @media all and (max-width: 640px) {
        flex-wrap: wrap;

        &.history__cell:first-of-type,
        &.history__cell:last-of-type {
          padding-bottom: 2.4rem;
        }

        &:not(:first-of-type) {
          .manage-users__entry {
            padding-top: 0.8rem;
          }
        }

        .history__table--actions {
          max-width: 100%;
          padding-right: 3rem;
          font-size: 1.4rem;
        }

        &.history__cell + .history__cell {
          padding-top: 0;
        }

        &:before {
          max-width: 100%;
          min-width: 100%;
          width: 100%;
          padding-bottom: 0;
          font-size: 1.2rem;
          text-transform: uppercase;
          font-family: "open_sanssemibold", arial, SansSerif, serif;
          opacity: 0.5;
        }
      }
    }
  }
}
