.sign-in--reset-pass {
  .input__inline-error {
    font-size: 1.2rem;
    margin-top: .3rem;
  }

  .register-form__list {
    font-size: 1.4rem;
    margin-bottom: 3rem;
  }

  .register-form__intro {
    margin-top: 3rem;
    line-height: 1.44;
  }

  .sign-in__show-pass-btn {
    position: absolute;
    width: 5rem;
    height: calc(100% - 2rem);
    right: 0;
    padding: 0 1.5rem;
    opacity: .5;
    transition: opacity .25s ease-in-out;

    &:hover {
      opacity: .7;
    }

    svg {
      fill: currentColor;
    }
  }

  .input {
    position: relative;
  }

  .input + .input {
    margin-bottom: 0;
    margin-top: 0 !important;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 5rem;

    &--multiple {
      justify-content: space-between;
    }
  }

  .btn-sign-in--reset-pass {
    width: 100%;
  }
}

.sign-in__center {
  margin: auto;
  display: flex;
  justify-content: center;
}

.update-account-password-modal {
  .modal__footer {
    display: flex;
    align-items: center;

    .inline-loader {
      margin-top: 0;
    }
  }
  .password-strength {
    padding-bottom: 4rem;
    padding-top: 1rem;
  }
}

.profile-change-password-modal .sign-in__form .input ~ .input {
  margin-bottom: 0;
}

.profile-change-password-modal .sign-in__form .input:first-of-type {
  margin-bottom: 3rem;
}

.profile-change-password-modal .sign-in__form .sign-in__show-pass-btn {
  height: calc(100% - 24px);
}

.profile-change-password-modal .sign-in__show-pass-btn {
  height: calc(100% - 23px);
}
