.lds-ring {
  display: flex;
  position: relative;
  width: 1.8rem;
  height: 1.8rem;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: all 0.25s ease-in-out;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 1.8rem;
    height: 1.8rem;
    margin: 0.8rem;
    border: 3px solid;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--inline-loader) transparent transparent transparent;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }

    &:nth-child(2) {
      animation-delay: -0.3s;
    }

    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
