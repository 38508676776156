.inline-tooltip {
  position: relative;
  z-index: 1;

  &:after,
  &:before {
    position: absolute;
    bottom: 100%;
    left: 50%;
    background: #1a1a1a;
    border: 1px solid #fff;
    opacity: 0;
    visibility: hidden;
    transition: transform .15s ease-in-out, opacity .15s ease-in-out, visibility .15s ease-in-out, margin-bottom .15s ease-in-out;
  }

  &:after {
    content: attr(data-title);
    word-break: break-word;
    margin-bottom: 1.6rem;
    max-width: 20rem;
    width: max-content;
    padding: 1rem 1.2rem;
    border-radius: 5px;
    font-size: 1.1rem;
    transform: translateX(-50%) translateY(-1rem);
    line-height: 1.33;
    color: var(--inline-tooltip-color);
  }

  &:before {
    content: "";
    transform: translateX(-50%) rotate(45deg);
    margin-bottom: 2.3rem;
    width: 1.2rem;
    height: 1.2rem;
    border-top: none;
    border-left: none;
    z-index: 1;
    transition: transform .15s ease-in-out, opacity .15s 0.024s ease-in-out, visibility .15s ease-in-out, margin-bottom .15s ease-in-out;
  }

  &:hover {
    &:after,
    &:before {
      opacity: 1;
      visibility: visible;
    }

    &:before {
      transform: translateX(-50%) rotate(45deg);
      margin-bottom: 1rem;
      transition: transform .15s ease-in-out, opacity .15s 0s ease-in-out, visibility .15s ease-in-out, margin-bottom .15s ease-in-out;
    }

    &:after {
      transform: translateX(-50%) translateY(0);
    }
  }

  &--bottom {
    &:after {
      transform: translateX(-50%) translateY(-1rem);
      left: 100%;
    }

    &:before {
      transform: translateX(-50%) rotate(45deg);
    }

    &:hover {
      &:before {
        transform: translateX(-50%) rotate(45deg);
        margin-bottom: 1rem;
        transition: transform .15s ease-in-out, opacity .15s 0s ease-in-out, visibility .15s ease-in-out, margin-bottom .15s ease-in-out;
      }

      &:after {
        transform: translateX(-50%) translateY(2rem);
      }
    }
  }
}
