*,
*:after,
*:before {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

.overlay--opened {
  overflow: hidden;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 6px;
  background: rgb(147 147 147 / 13%)
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgb(135 135 135 / 50%);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

main {
  margin: auto;
  width: 100%;
}
