.list {
  &,
  li {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &--standard {
    li {
      position: relative;
      padding-left: 1.3rem;
      margin: .8rem 0;

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        transform: translateY(-50%);
        background: currentColor;
      }
    }
  }
}
