.adv-search {
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  line-height: 1;
  height: 5rem;
  color: var(--base-color);
  background-color: rgba(255, 255, 255, 0);

  .input__element {
    padding-right: 5rem;
  }

  svg {
    width: 1.8rem;
    margin-right: 1rem;
    transform: rotate(0);
    transition: transform .25s ease-in-out;
  }

  &--collapsed {
    background: var(--btn-default-hover-bg);
    color: var(--btn-default-hover-color);

    svg {
      transform: rotate(90deg);
    }
  }
}
