.bank-detail-items {
  .input__title {
    display: block;
    margin-bottom: 0;
  }

  .input__label--datepicker .input__title {
    margin-bottom: .5rem;
  }

  .input__element {
    background: var(--input-bg-color-alt);
    border-radius: 3px;
    min-height: 4rem;
    color: var(--register-form-color);
    border: none !important;
    text-align: left;
    margin-top: .5rem;
  }

  .input__element--error {
    box-shadow: inset 0 0 0 3px var(--register-form-error-border);
  }

  .input__inline-element {
    font-size: 1.2rem;
    font-family: "open_sanssemibold", arial, sans-serif;
  }

  .MuiOutlinedInput-root {
    background: #fff;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .05);
    overflow: hidden;
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    width: 100%;
    border-radius: 3px;
    margin-top: 0;
    flex-direction: row-reverse;
  }

  input.MuiOutlinedInput-input {
    color: inherit;
    margin-top: 0;
    height: 4rem;
    padding: 0;
  }

  .input__label {
    position: relative;
  }
}

.bank-detail-modal {
  .modal__content {
    width: 55rem;
  }

  p {
    margin-bottom: 2rem;
  }

  strong {
    display: block;
    margin-bottom: .5rem;
  }
}
