.invoice-upload__form-wrapper {
  max-width: 70rem;
  margin: auto;
}

.invoice-upload {
  position: relative;
  padding: 5rem;
  font-size: 1.6rem;

  &__page-title {
    color: var(--page-header-color);
  }

  &__intro {
    line-height: 1.44;
  }

  &__content {
    .input + .input,
    .input + .select,
    .input + .gl-refs {
      //margin-top: 2.5rem;
    }
  }

  &__actions {
    display: flex;
    margin-top: 1.5rem;
    margin-bottom: 5rem;

    .inline-loader {
      margin: auto;
    }

    .no-available-data {
      margin: 0;
    }
  }

  &__action {
    display: flex;
    padding: 2rem;
    flex: 1;
    max-width: 50%;
    cursor: pointer;
    border-radius: 1rem;
    color: #fff;
    background: #27555c;
    border: 2px solid rgba(255, 255, 255, 0.05);
    margin: 0;
    transition: background-color 0.25s ease-in-out, opacity 0.25s ease-in-out;

    &--disabled {
      opacity: 0.4;
    }

    svg {
      width: 1.8rem;
      margin-left: 0.5rem;
    }

    &:not(&--active):hover {
      background: var(--header-link-active-color-hover);
    }

    &--active {
      background: var(--header-link-active-color-active);
    }

    & + .invoice-upload__action {
      margin-left: 2rem;
    }
  }

  .btn--question {
    padding: 0.5rem;
    display: inline-block;
    vertical-align: middle;
  }

  &__send {
    display: flex;
    align-items: center;
    padding: 1.5rem 3rem;
    line-height: 1;
    margin: 4rem auto;

    svg {
      width: 1.8rem;
      margin-left: 0.75rem;
    }

    &[disabled] {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }

  &--add {
    display: flex;
    white-space: nowrap;
    line-height: 1;
    align-items: center;
    margin: 4rem 0 2rem auto;

    svg {
      width: 2rem;
      margin-right: 0.5rem;
    }
  }

  .tooltip {
    position: absolute;
    top: 100%;
    background: var(--invoice-upload-tooltip-background);
    color: var(--invoice-upload-tooltip-color);
    font-size: 1.4rem;
    right: 0;
    left: 0;
    padding: 1rem;
    z-index: 2;
  }

  &__back-btn-wrapper {
    position: absolute;
    top: 2rem;
    left: 2rem;

    svg {
      vertical-align: middle;
    }
  }

  &__close-btn-wrapper {
    position: absolute;
    top: 2rem;
    right: 2rem;
    text-align: right;
  }

  .invoice-upload__back-btn,
  .invoice-upload__close-btn {
    color: #409b67;
  }

  h1 {
    font-size: 3rem;
    margin-top: 0;
  }

  input[type="radio"] {
    accent-color: #409b67;
  }

  select {
    background: #fff;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
  }

  input:disabled {
    //background-color: lightgray;
  }
}

.MuiButtonBase-root {
  font-size: 1.2rem !important;
  padding: 0 !important;
}

.MuiTypography-root {
  font-size: 1.2rem !important;
}

.PrivatePickersFadeTransitionGroup-root {
  font-size: 1.6rem !important;
  font-weight: bold !important;
}

.MuiTypography-root.MuiTypography-h4 {
  font-size: 3.5rem !important;
}

.MuiPickersArrowSwitcher-button .MuiSvgIcon-root {
  width: 1.6em !important;
  height: 1.6em !important;
}

.PrivateDatePickerToolbar-penIcon .MuiSvgIcon-root {
  font-size: 2.4rem !important;
}

.PrivatePickersYear-yearButton {
  font-size: 1.6rem !important;
}

.MuiButton-root.MuiButton-text {
  font-size: 1.4rem !important;
  font-weight: bold !important;
}

.css-l0iinn {
  font-size: 1.6rem !important;
}

@media (max-width: 1200px) {
  .invoice-upload {
    padding: var(--page-padding);
  }

  .invoice-upload__form-wrapper {
    //order: 2;
    //width: 100%;
    //max-width: 50rem;
    //margin-left: auto;
    //margin-right: auto;
    //margin-top: 2rem;
  }
}

.invoice__blocks {
  display: flex;
  margin: 0 -2rem;
  padding: 0 1rem;
  opacity: 1;
  visibility: visible;
  max-height: 50rem;
  transition: all 0.5s ease-in-out;

  .input__element {
    line-height: 1;
  }
}

.invoice__block {
  position: relative;
  padding: 0 1rem;
  margin: 0 0 2.5rem;
  flex: 1;

  .select.select--with-label {
    position: relative;

    .select__el {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-left: 2rem;
      color: var(--register-form-color);
      background: url("data:image/svg+xml,<svg fill='%23004D90' width='16px' height='9px' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 9'><path d='M13.8125 1.28125L13.2187 0.65625C13.0625 0.53125 12.8125 0.53125 12.6875 0.65625L7 6.3125L1.34375 0.65625C1.21875 0.53125 0.96875 0.53125 0.8125 0.65625L0.21875 1.28125C0.0624997 1.4375 0.0624997 1.65625 0.21875 1.8125L6.75 8.375C6.90625 8.5 7.125 8.5 7.28125 8.375L13.8125 1.8125C13.9687 1.65625 13.9687 1.4375 13.8125 1.28125Z'/></svg>"),
        var(--register-form-bg-color);
      background-repeat: no-repeat;
      background-position: calc(100% - 2rem) center;
      border: none;
    }
  }

  &--overhead {
    width: 50%;
  }

  &--upload {
    display: flex;
  }

  &--matter-number {
    position: relative;

    .btn {
      font-size: 1rem;
      text-transform: uppercase;
      font-family: "open_sanssemibold", Arial, sans-serif;
      letter-spacing: 0.5px;
      right: 10px;
      padding: 1rem;
      height: auto;
    }
  }

  .matter-number {
    .input__element {
      padding-right: 9.5rem;
    }
  }

  .invoice__item {
    position: relative;
    display: flex;
    outline: none;
    color: #fff;
    border: none;
    justify-content: center;
    align-items: center;
    background: #004d90;
    padding: 7px 13px;
    font-size: 1.2rem;
    line-height: 1;
    border-radius: 5px;
    cursor: pointer;
    margin: 2rem 1rem 0;

    &--no-invoice {
      cursor: auto;
      background: none;
      text-transform: uppercase;
      font-size: 1rem;
      color: var(--btn-error-bg);
      line-height: 1.44;
      margin: 24px 0 0;

      &-docs {
        color: currentColor;
      }
    }

    &-name {
      white-space: nowrap;
      max-width: 10rem;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 0.75rem;
      line-height: 1.44;
    }

    svg {
      width: 2rem;
    }

    &-close {
      position: absolute;
      white-space: nowrap;
      width: 1.8rem;
      background: var(--btn-error-bg);
      height: 1.8rem;
      display: flex;
      justify-content: center;
      align-items: center;
      right: -8px;
      top: -10px;
      border-radius: 50%;

      & > svg {
        width: 0.9rem;
      }
    }
  }
}

.matter-number {
  position: relative;

  &--actions {
    position: absolute;
    display: flex;
    align-items: center;
    top: 1.8rem;
    bottom: 0;
    right: 1rem;

    .inline-loader {
      margin-right: 0.75rem;
    }
  }
}

.invoice-modal .modal__content {
  width: 82rem;
}

.invoice__user-data {
  margin-bottom: 3rem;

  .input__label + .input__label {
    margin-top: 2rem;
  }
}

.invoice__section-title {
  position: relative;
  font-size: 1.8rem;
  font-family: "open_sanssemibold", Arial, sans-serif;
  margin: 0 0 2rem;
  padding: 0 0 1rem;
  border-bottom: 1px solid var(--invoice-section-title);
}

.invoice__section-action {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
  width: 3.2rem;
  height: 3.2rem;
  padding: 0;
  bottom: 1rem;

  svg {
    width: 1.2rem;
    transform: rotate(0);
    transition: transform 0.25s ease-in-out;
  }

  &--collapsed {
    svg {
      transform: rotate(-180deg);
    }
  }
}

.invoice__section-user-content {
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  visibility: hidden;
  transition: max-height 0.25s ease-in-out, visibility 0.25s ease-in-out,
    opacity 0.25s ease-in-out, opacity 0.25s ease-in-out;

  &--collapsed {
    opacity: 1;
    visibility: visible;
    max-height: 30rem;
  }
}

.related {
  &__error {
    margin: 0;
    text-transform: uppercase;
    font-size: 1.2rem;
    color: var(--generic-warning-color);
    font-family: open_sanssemibold, arial, sans-serif;
  }
}

.invoices__list {
  margin: 3rem 0;
}

.invoice-entry {
  position: relative;
  padding: 2rem 2rem;
  background: rgba(0, 77, 144, 0.05);
  border: 2px dashed var(--invoice-entry-border-color);

  &__subtitle {
    display: flex;
    // opacity: .6;
    opacity: 1;
    font-size: 1.1rem;
    text-transform: uppercase;

    &-warning {
      width: 1.4rem;
      margin-right: 0.5rem;
      color: #f8c138;

      svg {
        width: 100%;
        fill: currentColor;
      }
    }
  }

  & + .invoice-entry {
    margin-top: 2rem;
  }

  &__date {
    display: flex;
    align-items: center;
    opacity: 0.6;
    font-size: 1.2rem;
    line-height: 1;
    padding: 0 0 1.5rem;

    svg {
      width: 1.6rem;
      margin-right: 1rem;
    }
  }

  &__title {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    font-size: 2.5rem;
    margin: 0;

    strong {
      display: block;
      letter-spacing: 2px;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 20rem;
      text-align: right;
    }
  }

  &__amount {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-size: 1.4rem;
    bottom: 2rem;
    right: 2rem;
    margin: 0;

    &--overhead {
      position: relative;
      right: 0;
      bottom: 0;
      margin-top: 2.5rem;

      &:before {
        content: "";
        position: absolute;
        top: -1.5rem;
        right: 0;
        width: 18rem;
        height: 1px;
        background-color: var(--invoice-entry-border-color);
        opacity: 0.3;
      }
    }

    &--totals {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 2rem;

      .invoice-entry__amount-entry {
        display: flex;
        flex-direction: column;
        text-align: right;

        &:first-of-type {
          text-align: left;
        }
      }
    }

    .invoice-entry__subtitle {
      text-align: right;
    }

    strong {
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 10rem;
      text-align: right;
    }
  }

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .invoice-entry__amount {
      position: relative;
      bottom: 0;
      right: 0;
    }
  }

  &__rows {
    padding: 1.5rem 0;
    margin-bottom: 4rem;

    &--collapsable {
      position: relative;
      overflow: hidden;
      max-height: 16rem;
      transition: max-height 0.25s ease-in-out;

      &:after {
        content: attr(data-title);
        position: absolute;
        padding: 0 1.5rem;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        background-color: rgb(31 33 36);
        text-transform: uppercase;
        font-size: 1rem;
        font-family: "open_sanssemibold", Arial, sans-serif;
      }

      &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 4px;
        border-top: 1px dashed var(--invoice-entry-border-color);
      }

      &:before,
      &:after {
        opacity: 0.4;
        transition: opacity 0.25s ease-in-out;
        cursor: pointer;
      }

      &:hover {
        &:before,
        &:after {
          opacity: 0.8;
        }
      }

      &.invoice-entry__rows--collapsed {
        max-height: 50rem;
      }
    }

    .invoice-entry__expand {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  &__matter {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 1.5rem 0 0;
    font-size: 1.4rem;

    strong {
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 20rem;
      white-space: nowrap;
    }

    &--cms-contact {
      margin-right: auto;
      margin-left: 2rem;
    }
  }

  &__matters {
    display: flex;

    .invoice-entry__matter {
      margin-left: auto;
      margin-top: 0;
    }
  }

  &__actions {
    position: absolute;
    z-index: 1;
    display: flex;
    right: 0;
    top: 0;
    border: 2px solid var(--invoice-entry-border-color);
    border-top: 0;
    border-right: 0;

    .invoice-entry__action {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem 1.1rem;
      color: var(--base-color);
      background: var(--base-bg);
      cursor: pointer;
      border-radius: 0;
      transition: background-color 0.25s ease-in-out;

      svg {
        width: 1.4rem;
      }

      &--delete {
        color: var(--btn-error-bg-alt);
      }

      &:hover {
        background: var(--invoice-entry-action-bg-hover);
      }
    }
  }
}

.matter_number-button {
  opacity: 1;
  transition: all 0.25s ease-in-out;
}

.matter_number-button--disabled {
  opacity: 0.6;
}

.invoice-modal .input__inline-element {
  font-size: 1.2rem;
  font-family: "open_sanssemibold", arial, sans-serif;
}

.invoice__blocks {
  &--date {
    margin-bottom: 6rem;
  }

  .input {
    &__element {
      color: var(--register-form-color);
      background: var(--register-form-bg-color);
      transition: box-shadow 0.25s ease-in-out,
        background-color 0.25s ease-in-out, opacity 0.25s ease-in-out;

      &--error {
        box-shadow: inset 0 0 0 3px var(--register-form-error-border);
      }

      &--disabled {
        color: var(--register-form-color-disabled);
        background: var(--invoice-modal-disabled-bg);
      }
    }

    &__label {
      position: relative;
    }

    .suggestions + .input__inline-error {
      padding-left: 16rem;
    }
  }

  .gl-refs,
  .input,
  .select {
    .input__inline-error {
      position: absolute;
      top: 100%;
      margin-top: 0.2rem;
      left: 0;
      width: 100%;
    }
  }
}

.sending-invoice-loader {
  display: flex;
  justify-content: center;
}

.missing-gl {
  line-height: 1.44;
}

.overhead__blocks {
  display: flex;
  margin: 0 -2rem;
  padding: 0 1rem;

  &--last-item {
    margin-bottom: 4rem;
  }

  .overhead__block {
    width: 100%;
    padding: 0 1rem;

    .input__inline-error {
      color: var(--generic-error-color);
      padding-top: 0.2rem;
    }

    .input__inline-error--warning {
      color: var(--generic-warning-color);
    }

    .matter-number--actions {
      top: 0;
    }

    .btn {
      font-size: 1rem;
      text-transform: uppercase;
      font-family: "open_sanssemibold", Arial, sans-serif;
      letter-spacing: 0.5px;
      right: 10px;
      padding: 1rem;
      height: auto;
    }
  }
}

.gl-refs {
  position: relative;

  .input__title {
    margin-bottom: 0.5rem;
  }

  .select__el {
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.gl-refs__toggle-dropdown {
  position: absolute;
  top: 3.1rem;
  left: 1.1rem;
  bottom: 3rem;
  display: flex;
  border-radius: 3px;
  align-items: center;
  justify-content: center;
  padding: 0 0.8rem;
  background: var(--nav-header-fs-hover-color);
  color: var(--btn-default-color);

  svg {
    width: 1.2rem;
    transform: rotate(0deg);
    transition: transform 0.25s ease-in-out;
  }

  &[disabled] {
    cursor: auto;
    opacity: 0.6;
  }

  svg,
  svg path {
    fill: currentColor;
  }

  &--opened {
    svg {
      transform: rotate(180deg);
    }
  }
}

.gl-refs__placeholder {
  height: 5rem;
  border-radius: 3px;
  background: #fff;
  display: flex;
  color: #737373;
  padding-left: 4.7rem;
  align-items: center;
  user-select: none;
  font-size: 1.4rem;
  font-family: "open_sanssemibold", Arial, sans-serif;
  transition: border-radius 0.25s ease-in-out;

  &--opened {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.gl-refs__add {
  position: absolute;
  top: 2.8rem;
  right: 1rem;
  display: flex;
  line-height: 1;
  align-items: center;
  color: #fff;
  text-transform: uppercase;
  font-family: "open_sanssemibold", Arial, sans-serif;
  padding: 0.65rem 0.75rem;
  border-radius: 3px;
  letter-spacing: 0.5px;
  font-size: 1rem;
  background: var(--invoice-overhead-gl-ref-input-back-color);

  svg {
    width: 1.8rem;
    margin-right: 0.5rem;
  }
}

.gl-refs__entries {
  position: absolute;
  top: 100%;
  margin-top: -2rem;
  left: 0;
  right: 0;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  max-height: 14rem;
  overflow: auto;
  transform: translateY(2rem);
  transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in-out,
    transform 0.25s ease-in-out;

  &--opened {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }
}

.gl-refs__entry {
  display: flex;
  font-family: "open_sanssemibold", Arial, sans-serif;
  line-height: 1;
  align-items: center;
  color: #333;
  font-size: 1.2rem;
  background: var(--input-bg-color-alt);
  padding: 1rem 1.1rem;
}

.gl-refs__entry-title-wrap {
  width: 100%;
}

.gl-refs__entry-title {
  display: block;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 1.44;
}

.gl-refs__entry-amount {
  margin-left: auto;
}

.gl-refs__entry-actions {
  display: flex;
  align-items: center;
  justify-content: center;
}

.gl-refs__entry-action {
  padding: 0.7rem;
  width: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  height: 2.5rem;
  border-radius: 3px;
  color: #fff;

  svg {
    width: 100%;
  }

  &:first-of-type {
    margin-left: 1rem;
  }

  & + & {
    margin-left: 0.5rem;
  }

  &--edit {
    background: var(--nav-header-fs-hover-color);
  }

  &--remove {
    background: var(--generic-error-color);
  }
}

.inv-card {
  &__container {
    display: flex;
    flex-direction: column;
  }

  &__title {
    position: relative;
    text-transform: uppercase;
    font-family: "open_sanssemibold", Arial, sans-serif;
    font-size: 1.2rem;
    letter-spacing: 1px;
    margin-bottom: 2rem;
    color: rgb(255 255 255 / 80%);

    &:after {
      content: "";
      position: absolute;
      bottom: -8px;
      left: 0;
      width: 1rem;
      height: 2px;
      transition: background-color 0.25s ease-in-out;
      background-color: var(--invoice-section-title);
    }
  }

  &.invoice-upload__action--active {
    .inv-card__title:after {
      background-color: var(--invoice-section-title-active);
    }
  }

  &__content {
    line-height: 1.44;
  }
}

.overhead-modal-wrapper .modal__entry {
  position: relative;
  min-height: 57rem;
}

.overhead-wizard {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 40rem;
  min-height: 7rem;
  margin: 1rem auto 2rem;
  justify-content: space-between;

  &__separator {
    position: absolute;
    left: 9.5rem;
    right: 9.5rem;
    top: 50%;
    height: 2px;
    color: #523c13;
    background-color: #523c13;
    transition: all 0.25s ease-in-out;

    &:after,
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 1.4rem;
      height: 1.4rem;
      border-radius: 50%;
      border: 2px solid;
      border-color: inherit;
      transition: all 0.25s ease-in-out;
    }

    &:after {
      left: -1.4rem;
      transition: all 0.25s ease-in-out;
    }

    &:before {
      right: -1.4rem;
      transition: all 0.25s ease-in-out;
    }

    &--active {
      color: #d0a14b;
      background-color: #d0a14b;
      right: 20.5rem;

      &:after {
        background-color: inherit;
      }

      &:before {
        opacity: 0;
        background-color: inherit;
      }

      &--next-step {
        right: 9.5rem;

        &:before {
          opacity: 1;
        }
      }
    }
  }

  &__icon {
    display: block;
  }

  &__action {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--base-color);
    opacity: 1;
    transition: opacity 0.25s ease-in-out;

    svg {
      width: 2.2rem;
    }

    &--disabled {
      opacity: 0.3;
      pointer-events: none;
    }
  }

  &__title {
    text-align: center;
    font-family: "open_sanssemibold", Arial, sans-serif;
    font-size: 1.4rem;
    width: 8rem;
    line-height: 1.44;
  }
}

.overhead__gl-row {
  display: flex;
  align-items: flex-start;
  gap: 2rem;
}
