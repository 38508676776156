@font-face {
  font-family: 'open_sansbold';
  src: local('OpenSans-Regular-700'),
  url('./../fonts/opensans-bold-webfont.woff2') format('woff2'),
  url('./../fonts/opensans-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'open_sansbold_italic';
  src: local('OpenSans-Italic-800'),
  url('./../fonts/opensans-bolditalic-webfont.woff2') format('woff2'),
  url('./../fonts/opensans-bolditalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'open_sansextrabold';
  src: local('OpenSans-Regular-800'),
  url('./../fonts/opensans-extrabold-webfont.woff2') format('woff2'),
  url('./../fonts/opensans-extrabold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'open_sansitalic';
  src: local('OpenSans-Italic-400'),
  url('./../fonts/opensans-italic-webfont.woff2') format('woff2'),
  url('./../fonts/opensans-italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'open_sanslight';
  src: local('OpenSans-Regular-300'),
  url('./../fonts/opensans-light-webfont.woff2') format('woff2'),
  url('./../fonts/opensans-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'open_sanslight_italic';
  src: local('OpenSans-Italic-300'),
  url('./../fonts/opensans-lightitalic-webfont.woff2') format('woff2'),
  url('./../fonts/opensans-lightitalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'open_sansregular';
  src: local('OpenSans-Regular-400'),
  url('./../fonts/opensans-regular-webfont.woff2') format('woff2'),
  url('./../fonts/opensans-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'open_sanssemibold';
  src: local('OpenSans-Regular-500'),
  url('./../fonts/opensans-semibold-webfont.woff2') format('woff2'),
  url('./../fonts/opensans-semibold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'open_sanssemibold_italic';
  src: local('OpenSans-Italic-500'),
  url('./../fonts/opensans-semibolditalic-webfont.woff2') format('woff2'),
  url('./../fonts/opensans-semibolditalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


