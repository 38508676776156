body {
  font-size: 1.6rem;
  font-family: 'open_sansregular', Arial, sans-serif;
}

strong {
  font-family: 'open_sansbold', Arial, sans-serif;
  font-weight: normal;
}

svg {
  fill: currentColor;
}

.ellipsis-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/****************************************************
  COLORS
 */

body {
  color: var(--base-color);
  background-color: var(--base-bg);
  transition: color .25s ease-in-out, background-color .25s ease-in-out;
}

a {
  color: var(--base-link-color);
  text-decoration: none;
}

a:focus {
  outline: none;
}
