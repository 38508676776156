:root {
  --stepper-line-color: #cecece;
  --stepper-line-color-active: #72b750;
  --stepper-color: #7e7e7e;
  --stepper-color-active: #3c831c;

  --pass-strength-item: #cecece;
  --pass-strength-item-too-weak: #d55c31;
  --pass-strength-item-weak: #ccb641;
  --pass-strength-item-medium: #42c2c2;
  --pass-strength-item-strong: #4473d7;
  --auth-layout-bg: #faf9f7;
  --auth-layout-register-bg: rgb(134 148 153 / 11%);
  --auth-layout-register-logo-fill: #307480;
  --auth-layout-hr-bg: #000;
  --base-color: #333;
  --base-bg: #faf9f7;
  --overlay-bg: #fff;
  --base-link-color: #004d90;
  --logo-color: #fff;
  --input-bg-color: #fff;
  --input-bg-color-alt: #fff;
  --invoice-modal-disabled-bg: #d7d8d9;
  --input-placeholder-color: #afafaf;
  --input-disabled-bg: #dadbdf;
  --btn-default-color: #fff;
  --btn-default-bg: #004d90;
  --btn-default-hover-color: #fff;
  --btn-default-hover-bg: #422373;
  --btn-default-color-alt: #fff;
  --btn-default-bg-alt: #5fa67d;
  --btn-default-hover-color-alt: #fff;
  --btn-default-hover-bg-alt: #409b67;
  --btn-mute-color: #004d90;
  --btn-mute-hover-color: #afafaf;
  --btn-white-bg: #fff;
  --btn-white-color: #004d90;
  --btn-white-hover-bg: #422373;
  --btn-white-hover-color: #fff;
  --btn-transparent-border: #fff;
  --btn-transparent-color: #fff;
  --btn-transparent-bg: rgba(255, 255, 255, 0);
  --btn-transparent-hover-bg: #422373;
  --btn-transparent-hover-border: #422373;
  --btn-error-color: #fff;
  --btn-error-bg: #c40d14;
  --btn-error-bg-alt: #cb444a;
  --btn-error-hover-color: #fff;
  --btn-error-hover-bg: #830928;
  --login-form-forgot-color: #fff;
  --teaser-bg: #f1f4f6;
  --nav-color-header-fs: #8a949a;
  --nav-color-header-fs-active: #fff;
  --nav-header-fs-hover-color: #4789c3;
  --info-header-color: #959fb9;
  --login-footer-border-color: #eeeeee;
  --login-footer-bullet-bg: #c4c4c4;
  --login-footer-link-color: #004d90;
  --login-mobile-login-action-color: #fff;
  --login-mobile-login-action-description-color: #000;
  --logo-header-color: #fff;
  --nav-header-bg: #eeeeee;
  --nav-header-color: #b9b9b9;
  --nav-header-hover-color: #659fd2;
  --sidebar-title-color: #959fb9;
  --sidebar-bg-color: #f1f4f6;
  --home-link-color: #333;
  --sidebar-item-color: #004d90;
  --sidebar-bg-color-hover: #f1f4f6;
  --sidebar-item-color-hover: #004d90;
  --sidebar-border-item-color: #bfd2e3;
  --page-header-color: #566493;

  --page-header-voucher-page-color: #566493;

  --header-search-color: #fff;
  --header-search-bg: rgba(255, 255, 255, 0.2);
  --header-search-icon-color: #fff;
  --header-actions-color: #fff;
  --header-actions-bg: rgba(255, 255, 255, 0.2);
  --header-hover-actions-color: #fff;
  --header-hover-actions-bg: #422373;
  --header-actions-notification: #e40039;
  --header-actions-reset-info: #383838;
  --header-actions-avatar-bg: rgb(110, 110, 110);
  --header-link-color: #fff;
  --header-link-color-hover: #eaeff1;
  --header-link-active-color: #795b23;
  --header-link-item-color: #fff8eb;
  --header-link-active-color-hover: #242c39;
  --header-link-active-color-active: #242c39;

  --missing-payee-color: #cd5472;
  --missing-payee-color-alt: #deafba;

  --profile-popup-background-color: #185c66;
  --profile-popup-text-color: #fff;

  --moon-icon-bg: #6baae5;
  --sun-icon-bg: #dcd1ab;

  --nav-link-border: #886522;

  --page-content-meta-input-bg: #fff;
  --page-content-meta-input-bg--disabled: #eeeeee;
  --page-content-meta-input: #004d90;
  --company-dropdown-multiselect-border-color-option: #e6e8e9;
  --acm-form-selection: #3073af;
  --page-content-meta-input--disabled: #c0d1e2;
  --page-content-input-color: #004d90;
  --page-content-input-color--disabled: #a0a0a1;
  --page-content-error: #ff5722;
  --page-content-btn-color: #004d90;
  --page-content-btn-clear-color: #ff5722;
  --page-content-btn-color--hover: #94bde1;
  --page-content-btn-clear-color--hover: #94bde1;
  --page-meta-bg: #fff;
  --page-meta-bg-sticky: #fff;
  --page-padding: 3rem;
  --mobile-page-padding: 3rem 1.5rem;

  --tags-color: #004d90;
  --tags-color--disabled: #a0a0a1;
  --tags-border-color: #004d90;
  --tags-border-color--disabled: #c0d1e2;
  --tags-bg-color: #fff;
  --tags-bg-color--disabled: #eeeeee;
  --tags-hover-color: #fff;
  --tags-hover-border-color: #422373;
  --tags-hover-bg-color: #422373;

  --acc-group-item-background: #f1f4f6;
  --acc-group-item-background--disabled: #eeeeee;
  --acc-group-item-background--hover: #004d90;
  --acc-group-item-color: #004d90;
  --acc-group-item-color--disabled: #c0d1e2;
  --acc-group-item-color--hover: #fff;
  --acc-group-item-border: #bfd2e3;
  --acc-group-item-border--disabled: #c0d1e2;
  --acc-group-title: #959fb9;
  --acc-group-header-color: #fff;

  --overlay-background: #fff;
  --overlay-back-action-bg: #004d90;
  --overlay-back-action-color: #fff;
  --overlay-title-color: #959fb9;
  --overlay-subtitle-color: #959fb9;
  --overlay-block-border-color: #959fb9;
  --overlay-close-color: #368fcd;
  --overlay-loader-bg: rgb(233 233 233 / 60%);
  --overlay-loader-item: #368fcd;

  --loader-background: #f1f4f6;
  --loader-highlight-background: #fff;
  --scroll-loader-bg: #f1f4f6;
  --sticky-header-bg: #fff;

  --modal-bg: #1e1e20;
  --modal-close-icon-color: #fff;
  --modal-close-icon-hover-color: #afafaf;
  --modal-overlay-bg: rgba(30, 30, 32, 0.8);
  --modal-header-default-bg: #0d535f;
  --modal-header-default-bg-hover: #063b44;
  --modal-header-error-bg: #893535;
  --modal-header-notify-bg: #4079ac;
  --modal-header-notify-bg-hover: #1379d7;
  --modal-header-color: #fff;
  --modal-content-bg: #f1f4f6;
  --modal-color: #000;

  --notification-success-bg: #3e825b;
  --notification-warning-bg: #de6912;
  --notification-error-bg: #893535;
  --notification-info-bg: #4079ac;
  --notification-close-icon-color: #fff;
  --notification-error-icon-color: #fff;
  --notification-content-color: #fff;
  --notification-progress-bar-bg: rgba(255, 255, 255, 0.6);

  --error-icon-fill: #893535;
  --inline-loader: #959fb9;

  --table-header-bg: #f1f4f6;
  --table-header-color: #004d90;
  --table-header-minimize-border: #fff;
  --table-header-minimize-icon-bg: #959fb9;
  --table-header-minimized-bg: #004d90;
  --table-header-minimize-icon-bg-active: #fff;
  --table-indicator-bg: #004d90;
  --table-item-link: #004d90;
  --table-bulled-color: #fff;
  --table-bulled-c-bg: #ff9f81;
  --table-bulled-b-bg: #7acbe4;
  --table-bulled-r-bg: #ff827b;
  --table-row-hover: #fafafa;

  --card-default-background: #f1f4f6;
  --card-default-name: #959fb9;
  --card-default-title: #000000;
  --card-default-chevron: #004d90;
  --card-default-icon: #7acbe4;
  --card-default-initials: #ffffff;
  --card-default-icon-border: 0 solid;

  --card-hover-background: #004d90;
  --card-hover-text: #ffffff;
  --card-hover-action-item: #004d90;
  --card-hover-chevron: #ffffff;

  --contact-form-background: #f1f4f6;
  --contact-form-submit-disabled: #a0a0a1;
  --contact-form-input-text: #000;
  --contact-form-input-error-border: 1px solid #d94747;
  --contact-form-input-color--disabled: #a0a0a1;

  --table-cell-header-bg-r: rgba(255, 130, 122, 0.5);
  --table-cell-header-bg-c: rgba(255, 159, 129, 0.5);
  --table-cell-header-bg-b: rgba(122, 203, 228, 0.5);
  --table-cell-bg-r: rgba(255, 130, 122, 0.1);
  --table-cell-bg-c: rgba(255, 159, 129, 0.1);
  --table-cell-bg-b: rgba(122, 203, 228, 0.1);

  --editor-link-popup-bg: #f1f4f6;
  --editor-link-popup-bg-border: #d3d3d3;
  --editor-link-popup-separator-bg: #bfd2e3;
  --editor-link-remove-color: #e40039;
  --editor-link-link-color: #004d90;
  --editor-link-settings-color: #333;

  --editor-toolbar-bg: #eee;
  --editor-toolbar-button-bg: #cdcdcd;
  --editor-toolbar-button-bg-hover: #d7d7d7;
  --editor-toolbar-button-bg-active: #c9ccd7;
  --editor-toolbar-button-bg-disabled: rgba(162, 163, 168, 0.15);
  --editor-toolbar-button-box-shadow: rgba(0, 0, 0, 0);
  --editor-toolbar-button-box-shadow-hover: rgba(0, 0, 0, 0.1);
  --editor-toolbar-button-box-shadow-active: rgba(0, 0, 0, 0.1);
  --editor-toolbar-button-color: #333;
  --editor-toolbar-button-color-hover: #2b2b2b;
  --editor-toolbar-button-color-disabled: #6a6d77;
  --editor-toolbar-button-separator: #cdcdcd;
  --editor-color-reset-btn-bg: #fff;
  --editor-color-reset-highlight-btn-bg: #e40039;
  --editor-toolbar-select-options-color: #333;
  --editor-toolbar-select-options-bg: #cdcdcd;

  --edit-btn-bg: #2f753f;
  --edit-btn-hover-bg: #1a9e38;
  --edit-btn-active-bg: #247903;
  --edit-btn-color: #fff;

  --nav-bg: rgb(27, 28, 30, 0.85);
  --active-nav-color: rgb(255, 255, 255);

  --subtitle-color: #9da5b9;
  --subtitle-color-hover: #8294c7;
  --subtitle-color-active: #7386bc55;

  --home-card-bg: #e4e7e9;
  --home-card-bg-hover: #78a9d5;
  --home-card-color: #6b849d;
  --home-card-color-hover: #fff;
  --home-card-border: #c8d0d7;
  --home-card-border-hover: #37648d;

  --search-header-color: #9ba1b3;

  --invoice-upload-background: #e5e5e5;
  --invoice-upload-tooltip-background: black;
  --invoice-upload-tooltip-color: white;

  --company-profile-dt-color: #858585;
  --company-profile-dl-checked-background: #ddede4;

  --history-search-input-placeholder-color: #aaaaaa;
  --history-search-input-border-color: #eaeaea;
  --history-thead-color: white;
  --history-thead-background: #27555c;
  --history-thead-background-alt: #27555c;
  --history-even-table-row-background: #ecf5f0;
  --history-table-row-hover-background: #ecf5f0;
  --history-status-paid: #409b67;
  --history-status-in-progress: #2079b9;
  --history-status-on-hold: #d70000;

  --table-users-head-color: #fff;
  --table-users-head-background: #27555c;
  --table-users-row-hover: #dfebf6;
  --table-users-row-even: #ebf1f7;
  --table-users-row-odd: #fff;
  --table-users-mobile-row-border-color: #e7e7e7;
  --table-users-mobile-chevron-color: #959fb9;
  --table-clause-mobile-chevron-color: #959fb9;
  --table-users-delete-color: #fff;
  --table-users-delete-bg: #de2c58;
  --table-users-delete-color-hover: #fff;
  --table-users-delete-bg-hover: #9f2443;
  --table-users-update-bg: #27555c;
  --table-users-update-bg-hover: #3c8893;

  --table-users-role-color: #fff;
  --table-users-role-bg: #2c9ade;
  --table-users-role-color-hover: #fff;
  --table-users-role-bg-hover: #316686;
  --table-users-invite-color: #fff;
  --table-users-payees-bg: #22a69a;
  --table-users-payees-bg-hover: #0b796f;
  --table-users-invite-bg: #3fa12d;
  --table-users-invite-color-hover: #fff;
  --table-users-invite-bg-hover: #467716;
  --table-users-disable-color: #fff;
  --table-users-disable-bg: #b95913;
  --table-users-disable-color-hover: #fff;
  --table-users-disable-bg-hover: #793e14;
  --users-pending-color: #fff;
  --users-pending-background: #2c9ade;
  --users-pending: #b96930;
  --users-pending_2fa: #b99930;
  --users-disabled: #792814;
  --users-activated: #467716;
  --users-contributor: #364c6c;
  --users-user: #004d8f;
  --users-admin: #2c9ade;
  --users-supervisor: #1bb977;
  --checkbox-color: #fff;
  --checkbox-border-color: #afafaf;
  --checkbox-background-color: rgba(255, 255, 255, 0);
  --checkbox-border-color-checked: #10894c;
  --checkbox-background-color-checked: #10894c;
  --register-form-color: #333;
  --register-form-bg-color: #fff;
  --register-form-error-border: #c45e5e;
  --register-form-error-color: #e40039;
  --register-form-error-message-color: #ee446e;
  --register-form-color-disabled: rgba(0, 0, 0, 0.8);
  --register-form-bg-color-disabled: rgba(255, 255, 255, 0.8);
  --register-form-submit-bg-disabled: rgba(59, 76, 93, 0.8);
  --button-error-bg: #893535;
  --button-error-bg-hover: #672525;
  --button-notify-bg: #4079ac;
  --button-notify-bg-hover: #325c81;
  --generic-error-color: #e40039;
  --generic-error-color-lighter: #d53e63;
  --generic-warning-color: #ffcd51;
  --generic-suggestion-bg-color: #2a679c;

  --profile-tr-hover: rgba(0, 0, 0, 0.05);

  --invoice-section-title: rgba(255, 178, 0, 0.25);
  --invoice-section-title-active: rgb(168 125 39);
  --upload-color: #0c535f;
  --upload-color-border: #0c535f;
  --upload-color-hover: #fff;
  --upload-color-disabled: #fff;
  --inline-tooltip-color: #fff;
  --invoice-entry-border-color: #c7d3e5;
  --invoice-entry-action-bg-hover: #e5ebf3;
  --invoice-overhead-gl-ref-input-border-color: #4c88be;
  --invoice-overhead-gl-ref-input-back-color: #009688;
  --invoice-overhead-gl-ref-input-back-color-hover: #217e76;

  --bank-card-bg: #ecf0f3;
  --bank-card-alt-bg: #eae8f6;
  --bank-card-border-color: #e3ebef;
  --bank-card-alt-border-color: #e4e2ee;
  --bank-card-input-el-border: rgba(255, 255, 255, 0.8);
  --pagination-color: #333;
  --pagination-color-active: #fff;

  --invoice-overlay: rgba(115, 120, 121, 0.65);
  --invoice-overlay--title-border: rgba(171, 120, 3, 0.8);
  --invoice-overlay-title: #599cd5;
  --invoice-overlay-border: rgb(34 79 117 / 35%);
}

[data-theme="dark"] {
  --auth-layout-bg: #222;
  --auth-layout-register-bg: rgba(255, 255, 255, 0.03);
  --auth-layout-register-logo-fill: #fff;
  --auth-layout-hr-bg: #fff;
  --base-color: #fff;
  --base-bg: #1f1f1f;
  --base-link-color: #2485da;
  --overlay-bg: #343434;
  --nav-header-bg: #2a2a2a;
  --nav-header-color: #fff;
  --nav-header-hover-color: #659fd2;
  --sticky-header-bg: #242424;
  --page-meta-bg: #1f1f1f;
  --page-meta-bg-sticky: #1f1f1f;
  --page-content-meta-input-bg: #1f1f1f;
  --page-content-meta-input-bg--disabled: #2a2a2a;
  --page-content-meta-input: #4079ac;
  --page-content-meta-input--disabled: #404040;
  --page-content-input-color: #4079ac;
  --page-content-input-color--disabled: #505050;
  --page-content-btn-color: #4079ac;
  --page-content-btn-color--hover: #94bde1;
  --login-footer-border-color: #181818;
  --login-footer-bullet-bg: #333;
  --login-footer-link-color: #4079ac;
  --tags-color: #4079ac;
  --tags-color--disabled: #505050;
  --tags-border-color: #4079ac;
  --tags-border-color--disabled: #404040;
  --tags-bg-color: #1f1f1f;
  --tags-bg-color--disabled: #2a2a2a;
  --tags-hover-color: #fff;
  --tags-hover-border-color: #422373;
  --tags-hover-bg-color: #422373;
  --home-link-color: #fff;
  --page-header-color: #959fb9;

  --page-header-voucher-page-color: #fff;

  --modal-content-bg: #2b2b2b;
  --modal-color: #fff;

  --overlay-background: #fff;
  --overlay-back-action-bg: #004d90;
  --overlay-back-action-color: #fff;
  --overlay-title-color: #959fb9;
  --overlay-subtitle-color: #959fb9;
  --overlay-block-border-color: #959fb9;
  --overlay-close-color: #368fcd;
  --overlay-loader-bg: rgb(233 233 233 / 60%);
  --overlay-loader-item: #368fcd;

  --loader-background: #2a2a2a;
  --loader-highlight-background: #1f1f1f;
  --scroll-loader-bg: #2a2a2a;
  --overlay-loader-bg: #1f1f1f;
  --teaser-bg: #2b2b2b;
  --overlay-loader-item: #368fcd;
  --sidebar-bg-color: #2b2b2b;
  --sidebar-item-color: #4079ac;
  --sidebar-border-item-color: #2a2a2a;
  --sidebar-bg-color-hover: #f1f4f6;
  --sidebar-item-color-hover: #004d90;
  --acc-group-title: #959fb9;
  --acc-group-header-color: #1f1f1f;
  --acc-group-item-background: #2b2b2b;
  --acc-group-item-background--disabled: #2a2a2a;
  --acc-group-item-background--hover: #004d90;
  --acc-group-item-color: #4079ac;
  --acc-group-item-color--disabled: #505050;
  --acc-group-item-color--hover: #fff;
  --acc-group-item-border: #1f1f1f;
  --acc-group-item-border--disabled: #404040;
  --inline-loader: #4079ac;
  --table-header-bg: #2b2b2b;
  --table-header-color: #fff;
  --table-header-minimize-border: #1f1f1f;
  --table-header-minimized-bg: #4079ac;
  --table-header-minimize-icon-bg-active: #fff;
  --table-indicator-bg: #4079ac;
  --table-item-link: #4079ac;
  --table-row-hover: #212121;
  --btn-mute-color: #ffffff;

  --card-default-background: #2b2b2b;
  --card-default-name: #959fb9;
  --card-default-title: #4079ac;
  --card-default-chevron: #4079ac;
  --card-default-icon: rgba(0, 77, 144, 0);
  --card-default-initials: #004d90;
  --card-default-icon-border: 2px solid #004d90;

  --card-hover-background: #4079ac;
  --card-hover-text: #ffffff;
  --card-hover-action-item: #004d90;
  --card-hover-chevron: #ffffff;

  --contact-form-background: #2b2b2b;
  --error-icon-fill: #fff;

  --editor-link-popup-bg: #2b2b2b;
  --editor-link-popup-bg-border: #1a1a1a;
  --editor-link-popup-separator-bg: #1f1f1f;
  --editor-link-remove-color: #e40039;
  --editor-link-link-color: #4079ac;
  --editor-link-settings-color: #bfd2e3;
  --input-bg-color: #1f1f1f;
  --input-bg-color-alt: #f8f9fb;

  --contact-form-submit-disabled: #505050;
  --contact-form-input-text: #fff;

  --editor-toolbar-bg: #2b2b2b;
  --editor-toolbar-button-bg: rgba(0, 0, 0, 0);
  --editor-toolbar-button-bg-hover: #fff;
  --editor-toolbar-button-bg-active: #c9ccd7;
  --editor-toolbar-button-bg-disabled: rgba(162, 163, 168, 0.15);
  --editor-toolbar-button-box-shadow: rgba(0, 0, 0, 0);
  --editor-toolbar-button-box-shadow-hover: rgba(0, 0, 0, 0.5);
  --editor-toolbar-button-box-shadow-active: rgba(0, 0, 0, 0.5);
  --editor-toolbar-button-color: #d2dfec;
  --editor-toolbar-button-color-hover: #2b2b2b;
  --editor-toolbar-button-color-disabled: #6a6d77;
  --editor-toolbar-button-separator: #585858;
  --editor-color-reset-btn-bg: #fff;
  --editor-color-reset-highlight-btn-bg: #e40039;
  --editor-toolbar-select-options-color: #fff;
  --editor-toolbar-select-options-bg: #3d3d3e;

  --edit-btn-bg: #2f753f;
  --edit-btn-hover-bg: #1a9e38;
  --edit-btn-active-bg: #247903;
  --edit-btn-color: #fff;

  --nav-bg: rgb(27, 28, 30, 0.85);
  --active-nav-color: rgb(255, 255, 255);

  --subtitle-color: #707a95;
  --subtitle-color-hover: #d0d9ef;
  --subtitle-color-active: #505970;

  --home-card-bg: #222;
  --home-card-bg-hover: #313131;
  --home-card-color: #9d9d9b;
  --home-card-color-hover: #d7d7d1;
  --home-card-border: #31312f;
  --home-card-border-hover: #676764;

  --invoice-upload-background: #1f1f1f;
  --invoice-upload-tooltip-background: white;
  --invoice-upload-tooltip-color: black;

  --history-even-table-row-background: #222;
  --history-table-row-hover-background: #222;

  --company-profile-dt-color: #fff;
  --company-profile-dl-checked-background: #cff7d7;

  --table-users-head-background: #27555c;
  --table-users-head-color: #6b6b6b;
  --table-users-row-hover: #262626;
  --table-users-row-even: #222;
  --table-users-row-odd: #1f1f1f;
  --table-users-mobile-row-border-color: #292929;
  --table-users-mobile-chevron-color: #7acbe4;
  --table-clause-mobile-chevron-color: #7acbe4;
  --checkbox-color: #fff;
  --checkbox-border-color: #fff;
  --checkbox-background-color: hsla(0, 0%, 100%, 0);
  --checkbox-border-color-checked: #10894c;
  --checkbox-background-color-checked: #10894c;

  --profile-tr-hover: rgba(255, 255, 255, 0.05);

  --upload-color: #bfd6da;
  --upload-color-border: #0c535f;
  --upload-color-hover: #fff;
  --upload-color-disabled: #fff;
  --inline-tooltip-color: #fff;
  --invoice-entry-border-color: #40454c;
  --invoice-entry-action-bg-hover: #36393d;

  --bank-card-bg: #1f2427;
  --bank-card-alt-bg: #201f26;
  --bank-card-border-color: rgb(37 43 60);
  --bank-card-alt-border-color: #2e2d36;
  --bank-card-input-el-border: rgba(255, 255, 255, 0.2);

  --company-dropdown-multiselect-border-color-option: #e6e8e9;

  --pagination-color: #fff;

  --invoice-overlay: rgba(26, 28, 31, 0.7);
  --invoice-overlay--title-border: rgba(255, 178, 0, 0.25);
  --invoice-overlay-title: #84a9c4;
  --invoice-overlay-border: rgba(168, 181, 192, 0.35);
}
