.mobile-payee {
  position: relative;
  display: none;

  @media all and (max-width: 480px) {
    display: block;
  }

  &__content {
    position: absolute;
    top: calc(100% + 10px);
    left: 50%;
    background-color: var(--profile-popup-background-color);
    color: var(--profile-popup-text-color);
    padding: 0.4rem 1.4rem;
    text-align: center;
    z-index: 5;
    transform: translateX(-50%);
    white-space: nowrap;

    &:before {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: -9px;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid var(--profile-popup-background-color);
    }

    .header__payee--missing {
      font-size: 1.4rem;
      padding: 1rem 0;
      white-space: nowrap;
      color: var(--missing-payee-color-alt);
    }
  }

  &__action {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4rem;
    height: 4rem;
    background: var(--header-actions-bg);
    color: var(--header-actions-color);
    border-radius: 50%;
    border: none;
    outline: none;
    cursor: pointer;
    margin-left: 0;

    svg {
      width: 1.4rem;
    }

    &--card.header__payee--card {
      padding: 1rem;
    }
  }
}

.header__payee {
  font-size: 1.2rem;
  text-transform: uppercase;
  white-space: nowrap;

  @media all and (max-width: 480px) {
    display: none;
  }

  svg {
    width: 1.8rem;
    margin-right: 1rem;
  }

  &--content {
    display: flex;
    justify-content: center;
    align-items: center;

    @media all and (max-width: 640px) {
      font-size: 1rem;
    }
  }

  &--missing {
    color: var(--missing-payee-color);
  }

  &--card {
    display: flex;
    justify-items: center;
    align-items: center;
    padding: 1rem 1.5rem;
    color: #fff;
    line-height: 1;
    font-size: 1.4rem;
    font-family: "open_sanssemibold", Arial, sans-serif;

    &--movable {
      font-size: 1.4rem;
      text-transform: none;
      white-space: nowrap;
      position: fixed;
      right: 0;
      top: 0;
      height: 5.4rem;
      background-color: #000;
      padding: 0 2rem 0 2.4rem;
      z-index: 2;
      background: url("./../../../assets/images/header_bg.jpg") no-repeat 0;
      border-bottom: 2px solid #2a2d32d9;
      border-left: 2px solid #2a2d32d9;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: -1;
        background: rgba(19, 20, 21, 0.9);
      }

      .header__payee--card-trash {
        svg {
          margin-right: 0;
        }
      }
    }

    &-trash {
      margin-left: 1.5rem;
      color: var(--generic-warning-color);
      border-left: 1px solid rgb(51 51 51);
      padding-left: 1.5rem;

      svg {
        width: 1.6rem;
        transform: scale(1);
        transition: transform 0.15s ease-in-out;
      }
    }

    &:hover {
      .header__payee--card-trash svg {
        transform: scale(1.2);
      }
    }
  }
}

.app-header {
  position: relative;
  z-index: 3;
  background: url("./../../../assets/images/header_bg.jpg") no-repeat 0;
  border-bottom: 2px solid #2a2d32d9;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    background: rgba(19, 20, 21, 0.9);
  }

  &--full {
    max-width: 100%;

    @media all and (max-width: 900px) {
      padding: 2rem;
    }
  }

  .app-logo {
    width: 7.5rem;
    color: var(--logo-header-color);
    cursor: pointer;
    align-items: center;

    svg {
      width: 100%;
      height: 3.8rem;
    }
  }

  .search-form {
    svg {
      width: 18px;
      height: 21px;
      fill: var(--header-search-icon-color);
    }

    input,
    button {
      background-color: var(--header-search-bg);
      color: var(--header-search-color);
      border: none;
      padding: 0.5em;
    }

    input:focus,
    input:focus + button {
      --header-search-bg: white;
      --header-search-color: #385c7c;
      --header-search-icon-color: #385c7c;
      transition: background 0.5s;
      outline: none;
    }

    ::placeholder {
      color: white;
    }
  }
}

.app-nav {
  opacity: 1;
  visibility: visible;
  max-height: 10rem;
  transform: translateX(0);
  background: var(--nav-header-bg);
  transition: opacity 0.35s ease-in-out, visibility 0.35s ease-in-out,
    max-height 0.35s ease-in-out, transform 0.35s ease-in-out,
    background 0.25s ease-in-out;

  &--fs {
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 576px) {
  .app-header .app-logo {
    margin: 0 auto;
  }
}

@media (min-width: 576px) {
  .app-header .search-form input {
    width: 27rem;
  }
}
