.acceptable-use-policy-content {
  max-width: 90rem;
  margin-top: 3rem;

  h2,
  h3 {
    margin: 2rem 0;
  }

  p {
    line-height: 1.44;

    + h2,
    + h3 {
      margin-top: 4rem;
    }
  }

  p span {
    display: inline-block; /* Make each list item a block element */
    margin-left: 1.5em; /* Indent each list item */
  }
}
