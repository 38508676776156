.input {
  &__element,
  &__label {
    width: 100%;
  }

  &__title {
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
    text-transform: uppercase;
    font-family: open_sanssemibold, arial, sans-serif;
    transform: translateY(0) scale(1);
    transform-origin: 100% 0;
    transition: transform 0.15s ease-in-out;

    &--alt {
      position: absolute;
      top: 1.8rem;
      right: 0;
      z-index: -1;
    }
  }

  &__label {
    display: flex;
    flex-direction: column;

    &--alt {
      position: relative;

      .input__inline-element {
        margin-top: 0.35rem;
        font-size: 1rem;
      }
    }
  }

  &__element {
    min-height: 5rem;
    padding: 0 2rem;
    outline: none;
    line-height: 1;
    border: none;
    background: none;
    transition: border-bottom-color 0.15s ease-in-out;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);

    &--alt {
      border-bottom: 2px solid var(--input-bg-color-alt);
      padding-right: 0;

      &:focus {
        border-bottom-color: var(--invoice-overhead-gl-ref-input-border-color);
      }
    }

    &[type="password"] {
      appearance: textfield;

      &::-ms-reveal {
        display: none;
      }
    }

    &:not(&--alt) {
      background: var(--input-bg-color-alt);
      border-radius: 3px;
    }

    &--alt {
      color: var(--input-bg-color-alt);

      &:focus,
      &:not([value=""]) {
        & + .input__title {
          transform: translateY(-3rem) scale(0.8);
        }
      }
    }

    &::placeholder {
      color: var(--input-placeholder-color);
    }

    &[disabled] {
      opacity: 0.7;
      pointer-events: none;
      cursor: not-allowed;
      background: var(--input-disabled-bg);
    }

    &[type="number"]::-webkit-outer-spin-button,
    &[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type="number"] {
      -moz-appearance: textfield;
    }
  }

  &__notes {
    margin-top: 0.5rem;
    font-size: 1rem;
    font-family: open_sanssemibold, arial, sans-serif;
  }
}

.marginBottomClass {
  margin-bottom: 3.2rem;
}

.marginBottomClassOverhead {
  margin-bottom: 2.5rem;
}
