.company-search-form {

  width: 100%;
  padding: 1rem;

}

.company-search {
  position: relative;
  width: 100%;

  @media all and (max-width: 768px) {
    width: 100%;
    max-width: 25rem;
  }

  @media all and (max-width: 500px) {
    width: 100%;
    max-width: 100%;

    &-form {
      width: 100%;
    }
  }

  &__form {
    width: 100%;
    display: flex;
    align-items: center;

  }

  &__action {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 5rem;
    color: var(--input-placeholder-color);
    transition: color .25s ease-in-out;

    &:hover {
      color: #333;
    }

    svg {
      fill: currentColor;
    }
  }

  &__clear-all{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 3rem;
    top: 0;
    bottom: 0;
    width: 5rem;
    color: var(--btn-error-bg);
    svg{
      color: currentColor;
    }

    &:hover {
      color: #333;
    }

  }
}
