.input__inline-error {
  position: relative;
  min-height: 2rem;
  color: var(--generic-error-color);
}

.input__inline-element {
  position: absolute;
  opacity: 0;
  transform: translateX(-1rem);
  visibility: hidden;
  transition: opacity .25s ease-in-out, opacity .25s ease-in-out, visibility .25s ease-in-out;
}

.input__inline-error--warning {
  color: var(--generic-warning-color);
}

.slide-in-out-animation {
  &-enter.input__inline-element {
    transform: translateX(1rem);
    opacity: 0;
    visibility: hidden;
    transition: opacity .25s ease-in-out, visibility .25s ease-in-out, transform .25s ease-in-out;
  }

  &-enter-active.input__inline-element,
  &-enter-done.input__inline-element {
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
    transition: opacity .25s ease-in-out, visibility .25s ease-in-out, transform .25s ease-in-out;
  }

  &-exit.input__inline-element {
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
    transition: opacity .25s ease-in-out, visibility .25s ease-in-out, transform .25s ease-in-out;
  }

  &-exit-active.input__inline-element {
    transform: translateX(-1rem);
    opacity: 0;
    visibility: hidden;
    transition: opacity .25s ease-in-out, visibility .25s ease-in-out, transform .25s ease-in-out;
  }
}

