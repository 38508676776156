.users {
  min-height: calc(100vh - 23rem);
  padding: var(--page-padding);

  @media (max-width: 640px) {
    padding: var(--mobile-page-padding);
  }

  &__search--meta {
    display: flex;

    .adv-search {
      margin-left: 2rem;
    }
  }

  &__footer {
    margin-top: 4rem;
  }

  .inline-loader {
    display: block;
    width: auto;
    text-align: center;
    margin: auto;
  }

  .generic-not-found__actions {
    justify-content: center;
  }

  .generic-not-found {
    margin: auto;
    text-align: center;
  }

  &__title {
    color: var(--page-header-color);
    margin: 0;

    @media all and (max-width: 500px) {
      font-size: 1.8rem;
    }

    + .inline-loader {
      margin-top: 4rem;
    }
  }

  &__table-wrapper {
    position: relative;
    overflow: auto;
    min-height: 20rem;

    .users__table-loader {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 20rem;
      height: 20rem;
      opacity: 0;
      visibility: hidden;
      background: rgba(0, 0, 0, 0.2);
      display: flex;
      justify-content: center;
      align-items: center;
      transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in-out;

      &--visible {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__table {
    margin-top: 4rem;
    width: 100%;
    min-width: 150rem;
    table-layout: fixed;
    opacity: 1;
    transition: opacity 0.25s ease-in-out;

    &--loading {
      opacity: 0.6;
    }

    &--regular {
      margin-top: 0;

      .users__thead {
        .btn {
          padding: 0.1rem 0;
        }
      }
    }

    &--admin {
      .users__thead {
        color: #fff;
      }

      .users__cell--provider {
        width: 15rem;
      }

      .users__cell--status {
        width: 13rem;
      }

      .users__cell--role {
        width: 13rem;
      }

      .users__cell--email {
        position: sticky;
        top: 0;
        left: 0;
        z-index: 1;
        background: inherit;
        width: 27rem;

        @media all and (max-width: 991px) {
          width: 100%;
        }
      }

      .users__cell--company {
        width: 20rem;
      }

      .users__cell--username {
        width: 19rem;
      }
    }
  }

  &__thead {
    color: var(--table-users-head-color);
    background: var(--table-users-head-background);
    text-align: left;

    .users__tr {
      background: inherit;
    }
  }

  &__tbody &__tr {
    background: var(--table-users-row-odd);
    transition: background 0.25s ease-in-out;

    .users__cell:nth-of-type(2) {
      position: sticky;
      left: 0;
      top: 0;
      background: inherit;
    }
  }

  &__tbody &__tr:nth-of-type(2n) {
    background: var(--table-users-row-even);
  }

  &__tbody &__tr:not(.users__tr--loading):hover {
    background: var(--table-users-row-hover);
  }

  &__entry {
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 1.5rem 2rem;

    &--loading {
      padding-top: 5rem;
    }

    &--small {
      font-size: 1.4rem;
    }

    &:not(.users__entry--actions) {
      overflow: hidden;
    }
  }

  &__entry--provider {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    text-transform: capitalize;

    svg {
      width: 20px;
      height: 20px;
      margin-right: 1rem;
    }
  }

  &__entry--status {
    display: inline-block;
    line-height: 1;
    padding: 0.7rem 1rem;
    background: transparent;
    border-radius: 5px;
    font-size: 1.2rem;
    letter-spacing: 0.05rem;
    color: var(--users-pending-color);
    font-family: "open_sanssemibold", arial, SansSerif, serif;
    text-transform: uppercase;
  }

  &__entry--status-active {
    background: var(--users-activated);
  }

  &__entry--status-pending {
    background: var(--users-pending);
  }

  &__entry--status-pending_2fa {
    background: var(--users-pending_2fa);
  }

  &__entry--status-disabled {
    background: var(--users-disabled);
  }

  &__entry--status-user {
    background: var(--users-user);
  }

  &__entry--status-admin {
    background: var(--users-admin);
  }

  &__entry--status-supervisor {
    background: var(--users-supervisor);
  }

  &__entry--status-contributor {
    background: var(--users-contributor);
  }

  &__cell--action {
    text-align: right;
    width: 26rem;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
  }

  &__action {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    width: 4.6rem;
    height: 3.6rem;
    transition: background 0.25s ease-in-out, color 0.25s ease-in-out,
      opacity 0.25s ease-in-out;
    opacity: 1;
    border-radius: 3px;
  }

  &__action + &__action {
    margin-left: 1rem;
  }

  &__action[disabled] {
    opacity: 0.3;
    pointer-events: none;
  }

  &__action--payees {
    background: var(--table-users-payees-bg);
    color: var(--table-users-invite-color);
  }

  &__action--payees:not(:disabled):hover {
    background: var(--table-users-payees-bg-hover);
    color: var(--table-users-invite-color-hover);
  }

  &__action--invite {
    background: var(--table-users-invite-bg);
    color: var(--table-users-invite-color);
  }

  &__action--invite:not(:disabled):hover {
    background: var(--table-users-invite-bg-hover);
    color: var(--table-users-invite-color-hover);
  }

  &__action--role {
    background: var(--table-users-role-bg);
    color: var(--table-users-role-color);
  }

  &__action--role:not(:disabled):hover {
    background: var(--table-users-role-bg-hover);
    color: var(--table-users-role-color-hover);
  }

  &__action--role svg {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }

  &__action--disable {
    background: var(--table-users-disable-bg);
    color: var(--table-users-disable-color);
  }

  &__action--disable:not(:disabled):hover {
    background: var(--table-users-disable-bg-hover);
    color: var(--table-users-disable-color-hover);
  }

  &__action--delete {
    background: var(--table-users-delete-bg);
    color: var(--table-users-delete-color);
  }

  &__action--delete:not(:disabled):hover {
    background: var(--table-users-delete-bg-hover);
    color: var(--table-users-delete-color-hover);
  }

  &__action--update {
    background: var(--table-users-update-bg);
    color: var(--table-users-update-color);
  }

  &__action--update:not(:disabled):hover {
    background: var(--table-users-update-bg-hover);
    color: var(--table-users-update-color-hover);
  }
}

.add-user-modal {
  .input + .input {
    margin-top: 2rem;
  }
}

.password-modal .modal__content {
  width: 60rem;
}

.password-modal__input-el .input__element--error {
  box-shadow: inset 0 0 0 3px var(--register-form-error-border);
}

.role-modal__select {
  margin: 1rem 0 2rem;

  .select__el {
    min-height: 3.5rem;
  }
}

.role-modal__heading {
  margin: 0 0 1rem;
  text-decoration: underline;
  font-family: "open_sanssemibold", arial, SansSerif, serif;
}

.role-modal__list + .role-modal__heading {
  margin-top: 2rem;
}

.register-form__form-error-container {
  position: relative;
  display: block;
  height: 2rem;
}

.register-form__form-error {
  position: absolute;
  top: 1rem;
  color: var(--register-form-error-message-color);
}

.users__search-wrapper {
  .adv-search-container {
    gap: 2rem;

    @media all and (max-width: 991px) {
      flex-wrap: wrap;
    }
  }

  .users-clear-filters {
    &__content {
      @media all and (max-width: 991px) {
        display: none;
      }
    }

    svg {
      @media all and (max-width: 991px) {
        margin-right: 0;
      }
    }

    @media all and (max-width: 500px) {
      bottom: 100%;
      top: auto;
      margin-bottom: 2rem;
      left: auto;
      right: 5rem;
    }
  }

  .currency-selectors {
    width: 100%;
    max-width: 30rem;
  }
}

.users__search--meta {
  @media all and (max-width: 768px) {
    .adv-search {
      svg {
        margin-right: 0;
      }
    }
    .adv-search__content {
      display: none;
    }
  }

  @media all and (max-width: 500px) {
    .adv-search {
      margin-left: 0;
    }
  }
}

@media all and (max-width: 991px) {
  .users {
    &__table,
    &__tbody,
    &__th,
    &__thead,
    &__tr {
      display: block;
    }

    &__table {
      min-width: 100%;
    }

    &__thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr {
      overflow: hidden;
      border: 1px solid var(--table-users-mobile-row-border-color);
      background: none !important;
      transition: max-height 0.45s ease-in-out !important;

      &:not(.users__tr--no-data):not(.users__tr--loading) {
        max-height: 5.1rem;
      }

      &:hover {
        background: none !important;
      }

      &:not(.users__tr--no-data):not(.users__tr--loading).users__tr--collapsed {
        max-height: 60rem;

        td:first-of-type:before {
          transform: rotate(-135deg);
        }
      }
    }

    tr + tr {
      border-top: none;
    }

    td {
      position: relative;
      display: flex;
      width: 100%;
      border: none;
      background: none !important;
    }

    td:not([colspan="8"]):before {
      content: attr(data-cell-name);
      min-width: 16rem;
      max-width: 16rem;
      width: 16rem;
      padding: 1.5rem 2rem;
    }

    td {
      position: relative;
      display: flex;
      width: 100%;
      border: none;
      background: none !important;

      &:not([colspan="8"]):before {
        content: attr(data-cell-name);
        min-width: 16rem;
        max-width: 16rem;
        width: 16rem;
        padding: 1.5rem 2rem;
      }

      &:not([colspan="8"]):first-of-type {
        cursor: pointer;

        &:before {
          content: "";
          position: absolute;
          right: 2rem;
          top: 1.7rem;
          width: 1rem;
          min-width: 1rem;
          max-width: 1rem;
          border-radius: 0;
          height: 1rem;
          padding: 0;
          border: 2px solid var(--table-users-mobile-chevron-color);
          border-top: none;
          border-left: none;
          transform: rotate(45deg);
          transition: transform 0.45s ease-in-out;
        }
      }

      @media all and (max-width: 640px) {
        flex-wrap: wrap;

        &:not(:first-of-type) {
          .manage-users__entry {
            padding-top: 0.8rem;
          }
        }

        &:before {
          max-width: 100%;
          min-width: 100%;
          width: 100%;
          padding-bottom: 0;
          font-size: 1.2rem;
          text-transform: uppercase;
          font-family: "open_sanssemibold", arial, SansSerif, serif;
          opacity: 0.5;
        }

        &:last-of-type {
          margin-top: 2rem;

          &:before {
            display: none;
          }
        }
      }
    }
  }
}

.users-search-multiselect {
  .multi-select__option {
    text-transform: capitalize;
  }
}

.users-pagination {
  margin-bottom: 2rem;
}

.users-search {
  @media (max-width: 768px) {
    position: relative;
    width: 25rem;
  }

  @media (max-width: 550px) {
    position: relative;
    width: 100%;

    .input__element {
      padding: 0 1rem;
    }
  }
}

.users-search--alt .input__element {
  padding-right: 5rem;
}

.users-search-form {
  @media all and (max-width: 768px) {
    .history-clear-filters svg {
      margin-right: 0;
    }
  }
}

.excel-btn {
  background: var(--checkbox-border-color-checked);
  color: white;
  margin-left: auto;
  transition: opacity 0.25s ease-in-out;
  opacity: 1;

  &:disabled {
    opacity: 0.5;
  }

  @media all and (max-width: 900px) {
    position: absolute;
    bottom: 100%;
    right: 0;
    margin-bottom: 2rem;
  }

  @media all and (max-width: 500px) {
    font-size: 1.4rem;
    padding: 0.9rem;
    margin-bottom: 0;
    margin-top: 0.5rem;
    bottom: auto;
    top: 0;
    right: 0;
  }
}
