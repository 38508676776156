.btn {
  border: none;
  background: none;
  outline: none;
  box-shadow: none;
  padding: 1.6rem;
  border-radius: 3px;
  cursor: auto;

  &:not([disabled]) {
    cursor: pointer;
  }

  &--default:not([disabled]) {
    background: var(--btn-default-bg);
    color: var(--btn-default-color);
    transition: background .25s ease-in-out, color .25s ease-in-out;

    &:not([disabled]):hover {
      background: var(--btn-default-hover-bg);
      color: var(--btn-default-hover-color);
    }
  }

  &--default:disabled {
    background: var(--btn-default-bg);
    color: var(--btn-default-color);
    opacity: .4;
    cursor: auto;
  }

  &--alt:not([disabled]) {
    background: var(--btn-default-bg-alt);
    color: var(--btn-default-color-alt);
    transition: background .25s ease-in-out, color .25s ease-in-out;

    &:not([disabled]):hover {
      background: var(--btn-default-hover-bg-alt);
      color: var(--btn-default-hover-color-alt);
    }
  }

  &--alt:disabled {
    background: var(--btn-default-bg-alt);
    color: var(--btn-default-color);
    opacity: .4;
    cursor: auto;
  }

  &--white:not([disabled]) {
    background: var(--btn-white-bg);
    color: var(--btn-white-color);
    transition: background .25s ease-in-out, color .25s ease-in-out;

    &:hover {
      background: var(--btn-white-hover-bg);
      color: var(--btn-white-hover-color);
    }
  }

  &--transparent:not([disabled]) {
    border: 1px solid var(--btn-transparent-border);
    color: var(--btn-transparent-color);
    background: var(--btn-transparent-bg);
    transition: border .25s ease-in-out, color .25s ease-in-out, background .25s ease-in-out;

    &:hover {
      background: var(--btn-transparent-hover-bg);
      border: 1px solid var(--btn-transparent-hover-border);
      color: var(--btn-transparent-hover-color);
    }
  }

  &--short {
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
  }

  &--long {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  &--error {
    color: var(--btn-error-color);
    background: var(--btn-error-bg);
    transition: color .25s ease-in-out, background .25s ease-in-out;

    &:not([disabled]):hover,
    &[disabled] {
      color: var(--btn-error-hover-color);
      background: var(--btn-error-hover-bg);
    }
  }

  &--mute {
    color: var(--btn-mute-color);
    transition: color .25s ease-in-out;

    &:hover {
      color: var(--btn-mute-hover-color);
    }
  }
}

.btn--reset {
  border: none;
  background: none;
  outline: none;
  box-shadow: none;
  padding: 0;
  cursor: pointer;
}
