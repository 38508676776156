.bank-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 4rem;

  .input__element {
    background: none;
    min-height: 3rem;
    text-align: right;
    border: 1px solid var(--bank-card-input-el-border);
    border-radius: 0;
    margin-top: -7px;
    color: var(--base-color);

    &.input__element--error {
      border: 1px solid rgba(175, 10, 10, 0.65);
    }
  }
}

.bank-container {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 4rem;
  max-width: 45rem;
  width: 100%;

  &__loader {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.1);
  }
}

.bank-card {
  position: relative;
  margin-right: 4rem;
  margin-bottom: 2.5rem;
  padding: 3.5rem;
  width: 100%;
  flex-wrap: wrap;
  background: var(--bank-card-bg);
  border: 2px solid var(--bank-card-border-color);

  &--alt {
    background: var(--bank-card-alt-bg);
    border: 2px solid var(--bank-card-alt-border-color);
  }

  &__entry {
    display: block;
    min-height: 2.2rem;
  }

  &:before {
    content: attr(data-title);
    position: absolute;
    top: -3.9rem;
    padding: 1.3rem;
    border: inherit;
    background: inherit;
    border-bottom: none;
    left: -2px;
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: "open_sanssemibold", arial, sans-serif;
  }

  dt,
  dd {
    width: 50%;
    letter-spacing: 1px;

    @media all and (max-width: 500px) {
      width: 100%;
    }
  }

  dt {
    opacity: 0.5;
  }

  dd {
    margin-left: 0;
    text-align: right;
    font-family: "open_sanssemibold", Arial, sans-serif;
    margin-bottom: 3rem;

    @media all and (max-width: 500px) {
      margin-top: 1rem;
      margin-bottom: 1.2rem;
      text-align: left;
    }
  }

  dt:not(:last-of-type),
  dl:not(:last-of-type) {
    margin-bottom: 3rem;

    @media all and (max-width: 500px) {
      margin-bottom: 0;
    }
  }
}

.bank-card--action {
  align-self: flex-start;
  opacity: 1;
  transition: all 0.25s ease-in-out;

  &[disabled] {
    opacity: 0.6;
  }

  & + & {
    margin-left: 2rem;
  }

  &--save {
    opacity: 1;
    transition: all 0.25s ease-in-out;

    &[disabled] {
      opacity: 0.6;
    }
  }
}

.bank-details__datepicker {
  &.MuiTextField-root {
    position: relative;
    top: 7px;
    margin-top: -1.5rem;
    width: 100%;
    overflow: hidden;
    border: 1px solid rgba(255, 255, 255, 0.1);
  }

  &.bank-details__datepicker--error {
    border: 1px solid rgba(175, 10, 10, 0.65);
  }

  .MuiInputBase-input {
    text-align: right;
    color: var(--base-color);
    font-size: 1.6rem;
    height: 3rem;
    padding: 0;
  }

  .MuiIconButton-root {
    color: var(--base-color);
    opacity: 0.6;
    margin-left: 0.5rem;
  }

  .MuiButtonBase-root:after {
    left: -25rem;
  }

  fieldset {
    border: none;
  }
}
