.error-boundary {
  width: 100%;
  max-width: 640px;
  margin: auto;
  padding: 4rem 0;

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 15rem;
    }
  }

  h3 {
    font-size: 1.8rem;
    text-align: center;
    margin: 1rem 0 4rem;
  }

  &__action {
    border-radius: 3px;
    box-shadow: none;
    outline: none;
    border: none;
    background: #004D90;
    color: #fff;
    text-align: center;
    padding: 1rem 2rem;
    margin-bottom: 4rem;
  }
}
