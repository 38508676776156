.list-item {
  display: flex;
  align-items: center;
  width: 100%;
  color: #4079ad;
  word-break: break-all;

  :hover {
    background: var(--page-content-meta-input);
    color: #fff;
  }

  &__selected{
    background: var(--acm-form-selection);
    color: #fff;
  }
  label {
    width: 100%;
    padding: 1rem;
    cursor: pointer;
  }

  input {
    margin-left: 0;
  }

  input[type=radio] {
    display: none;
  }
}