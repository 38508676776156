.select {
  &--with-label {
    display: flex;
    flex-direction: column;
  }

  &__el {
    appearance: none;
    min-height: 5rem;
    border-radius: 3px;
    padding: 0 4rem 0 1rem;
    background: var(--page-content-meta-input-bg);
    border: 1px solid var(--page-content-meta-input);
    color: var(--page-content-input-color);
    cursor: pointer;
    background: url("data:image/svg+xml,<svg fill='%23004D90' width='16px' height='9px' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 9'><path d='M13.8125 1.28125L13.2187 0.65625C13.0625 0.53125 12.8125 0.53125 12.6875 0.65625L7 6.3125L1.34375 0.65625C1.21875 0.53125 0.96875 0.53125 0.8125 0.65625L0.21875 1.28125C0.0624997 1.4375 0.0624997 1.65625 0.21875 1.8125L6.75 8.375C6.90625 8.5 7.125 8.5 7.28125 8.375L13.8125 1.8125C13.9687 1.65625 13.9687 1.4375 13.8125 1.28125Z'/></svg>")
      no-repeat;
    background-position: calc(100% - 0.75rem) center;
    transition: border 0.25s ease-in-out, color 0.25s ease-in-out,
      background 0.25s ease-in-out;

    &--error {
      box-shadow: inset 0 0 0 3px var(--register-form-error-border);
    }

    &:focus {
      outline: none;
    }
  }

  &--disabled {
    .select__el {
      color: var(--editor-toolbar-button-color-disabled) !important;
      border-color: var(--editor-toolbar-button-color-disabled) !important;
      background: url("data:image/svg+xml,<svg fill='%236a6d77' width='16px' height='9px' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 9'><path d='M13.8125 1.28125L13.2187 0.65625C13.0625 0.53125 12.8125 0.53125 12.6875 0.65625L7 6.3125L1.34375 0.65625C1.21875 0.53125 0.96875 0.53125 0.8125 0.65625L0.21875 1.28125C0.0624997 1.4375 0.0624997 1.65625 0.21875 1.8125L6.75 8.375C6.90625 8.5 7.125 8.5 7.28125 8.375L13.8125 1.8125C13.9687 1.65625 13.9687 1.4375 13.8125 1.28125Z'/></svg>")
        no-repeat !important;
      background-position: calc(100% - 0.75rem) center !important;
      cursor: auto;
    }
  }

  &--alt {
    .select__el {
      border: 1px solid var(--input-bg-color-alt);
      color: #333;
      cursor: pointer;
      background: var(--input-bg-color-alt)
        url("data:image/svg+xml,<svg fill='%23004D90' width='16px' height='9px' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 9'><path d='M13.8125 1.28125L13.2187 0.65625C13.0625 0.53125 12.8125 0.53125 12.6875 0.65625L7 6.3125L1.34375 0.65625C1.21875 0.53125 0.96875 0.53125 0.8125 0.65625L0.21875 1.28125C0.0624997 1.4375 0.0624997 1.65625 0.21875 1.8125L6.75 8.375C6.90625 8.5 7.125 8.5 7.28125 8.375L13.8125 1.8125C13.9687 1.65625 13.9687 1.4375 13.8125 1.28125Z'/></svg>")
        calc(100% - 0.75rem) no-repeat;
    }
  }
}

[data-theme="dark"] {
  .select__el {
    background: url("data:image/svg+xml,<svg fill='%234079AC' width='16px' height='9px' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 9'><path d='M13.8125 1.28125L13.2187 0.65625C13.0625 0.53125 12.8125 0.53125 12.6875 0.65625L7 6.3125L1.34375 0.65625C1.21875 0.53125 0.96875 0.53125 0.8125 0.65625L0.21875 1.28125C0.0624997 1.4375 0.0624997 1.65625 0.21875 1.8125L6.75 8.375C6.90625 8.5 7.125 8.5 7.28125 8.375L13.8125 1.8125C13.9687 1.65625 13.9687 1.4375 13.8125 1.28125Z'/></svg>")
      no-repeat;
    background-position: calc(100% - 0.75rem) center;
  }

  .select--alt {
    .select__el {
      border: 1px solid var(--input-bg-color-alt);
      color: #333;
      cursor: pointer;
      background: var(--input-bg-color-alt)
        url("data:image/svg+xml,<svg fill='%23004D90' width='16px' height='9px' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 9'><path d='M13.8125 1.28125L13.2187 0.65625C13.0625 0.53125 12.8125 0.53125 12.6875 0.65625L7 6.3125L1.34375 0.65625C1.21875 0.53125 0.96875 0.53125 0.8125 0.65625L0.21875 1.28125C0.0624997 1.4375 0.0624997 1.65625 0.21875 1.8125L6.75 8.375C6.90625 8.5 7.125 8.5 7.28125 8.375L13.8125 1.8125C13.9687 1.65625 13.9687 1.4375 13.8125 1.28125Z'/></svg>")
        calc(100% - 0.75rem) no-repeat;
    }
  }
}

.switch-element {
  position: relative;
  z-index: 1;

  &:after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 3px;
    transition: background 0.15s ease-in-out;
  }

  .select__el {
    min-height: 3.1rem;
    padding: 0 4rem 0 1.5rem;
    color: var(--editor-toolbar-select-options-color);
    border: none;
    background-color: var(--editor-toolbar-select-options-bg);
    background-image: url("data:image/svg+xml,<svg fill='%234079AC' width='16px' height='9px' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 9'><path d='M13.8125 1.28125L13.2187 0.65625C13.0625 0.53125 12.8125 0.53125 12.6875 0.65625L7 6.3125L1.34375 0.65625C1.21875 0.53125 0.96875 0.53125 0.8125 0.65625L0.21875 1.28125C0.0624997 1.4375 0.0624997 1.65625 0.21875 1.8125L6.75 8.375C6.90625 8.5 7.125 8.5 7.28125 8.375L13.8125 1.8125C13.9687 1.65625 13.9687 1.4375 13.8125 1.28125Z'/></svg>");
    background-repeat: no-repeat;
    background-position: calc(100% - 1.25rem) center;
    background-size: 1.2rem;
  }

  &:hover:after {
    background: rgba(255, 255, 255, 0.15);
  }
}

.tooltip-container {
  display: inline-block;
  position: relative;
  width: 100%;
}

.tooltip-trigger::after {
  content: attr(data-tooltip);
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px 10px;
  position: absolute;
  z-index: 1000;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s ease-in-out;
}

.tooltip-trigger:hover::after {
  visibility: visible;
  opacity: 1;
  font-size: 1.6rem;
  font-family: "open_sansregular", Arial, sans-serif;
}
