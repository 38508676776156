.acm-form {
  margin-top: 2rem;

  .matter-number--actions {
    top: 0;
    bottom: 2.3rem;
  }

  .input__inline-error {
    font-size: 1.4rem;
    margin-top: .5rem;
  }
}

.add-user-modal .acm-form .input + .input {
  margin-top: 1rem;
}

.acm-form-valid .input__element {
  box-shadow: inset 0 0 0 3px var(--checkbox-border-color-checked);
}

.acm-modal {
  margin-top: 2rem;

  .matter-number--actions {
    top: 1.6rem;
    bottom: 2.3rem;
  }

  .input__inline-error {
    font-size: 1.4rem;
    margin-top: .5rem;
  }
}

.add-company-form {
  &__toggle {
    margin-bottom: 2rem;
  }

  .toggle__entry {
    background-color: #2596d2;
  }

  .toggle__element:checked + .toggle__entry {
    background-color: var(--modal-header-default-bg);
  }

  .matter-number--actions {
    top: 0;
    bottom: 2.5rem;
  }

  hr {
    width: 50%;
    margin: 1.4rem auto 2.2rem;
    opacity: .1;
    height: 1px;
    background-color: var(--auth-layout-hr-bg);
    border: none;
  }

  &__entries {
    padding-bottom: 2rem;

    .input__label + .input__label {
      margin-top: 2rem !important;
    }
  }

  &__clear {
    opacity: 1;
    visibility: visible;

    &:after {
      display: none;
    }

    svg {
      margin-right: 0;
    }
  }
}
