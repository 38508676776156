.textArea {

  &__element,
  &__label {
    width: 100%;
    //TODO confirm with Alex he doesn't want borders here
    //border: 1px solid var(--page-content-meta-input);
    //transition: border 0.25s ease-in-out, color 0.25s ease-in-out, background 0.25s ease-in-out;
  }

  &__label {
    display: flex;
  }

  &__element {
    min-height: 5rem;
    min-width: 100%;
    padding: 1.5rem 2rem;
    outline: none;
    border: none;
    background: var(--input-bg-color);
    border-radius: 3px;

    &::placeholder {
      color: var(--input-placeholder-color);
    }
  }
}
