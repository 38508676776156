.users__header {
  position: relative;
  margin-top: 3rem;
  align-items: flex-start;
  justify-content: space-between;

  &--action {
    white-space: nowrap;
    transition: all .25s ease-in-out;
    height: 5rem;

    @media all and (max-width: 500px) {
      position: absolute;
      right: 0;
      bottom: 100%;
      margin-bottom: 2rem;
    }

    svg {
      @media all and (max-width: 991px) {
        margin-right: 0 !important;
      }
    }

    &-content {
      @media all and (max-width: 991px) {
        display: none;
      }
    }

    &[disabled] {
      opacity: .6;
    }
  }
}
