.contact-us__wrapper {
  max-width: 90rem;

  .contact-us__row {
    display: flex;
    gap: 2rem;

    .textArea__label {
      display: flex;
      flex-wrap: wrap;

      .input__inline-error {
        width: 100%;
      }
    }

    & + .contact-us__row {
      margin-top: 1rem;
    }

    @media all and (max-width: 640px) {
      flex-wrap: wrap;
    }
  }

  .contact-us__counter {
    position: absolute;
    color: currentColor;
    right: 0;
    bottom: 0;
    margin-bottom: .5rem;
    font-size: 1.2rem;
    text-transform: uppercase;
    transition: color .25s ease-in-out;

    &--error {
      color: var(--generic-warning-color)
    }
  }

  .select--alt .select__el,
  .textArea__element {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
    transition: box-shadow .25s ease-in-out;
  }

  .invoice-input--error {
    .textArea__element {
      box-shadow: inset 0 0 0 3px var(--register-form-error-border);
    }
  }

  .invoice-input {
    .input__element {
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
    }

    .input__title {
      color: var(--base-color);
    }
  }

  .invoice-select__container .input__title {
    color: var(--base-color);
  }

  .select__el {
    border: none;
  }

  .select--disabled .select__el {
    background: var(--input-bg-color-alt) url("data:image/svg+xml,<svg fill='%23004D90' width='16px' height='9px' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 9'><path d='M13.8125 1.28125L13.2187 0.65625C13.0625 0.53125 12.8125 0.53125 12.6875 0.65625L7 6.3125L1.34375 0.65625C1.21875 0.53125 0.96875 0.53125 0.8125 0.65625L0.21875 1.28125C0.0624997 1.4375 0.0624997 1.65625 0.21875 1.8125L6.75 8.375C6.90625 8.5 7.125 8.5 7.28125 8.375L13.8125 1.8125C13.9687 1.65625 13.9687 1.4375 13.8125 1.28125Z'/></svg>") calc(100% - 0.75rem) no-repeat !important;
  }
}
