.input {
  &--checkbox {
    user-select: none;
    cursor: pointer;
    opacity: 1;
    transition: opacity .25s ease-in-out;

    &--disabled {
      opacity: .5;
      pointer-events: none;
    }

    .input__checkbox-value {
      position: relative;
      padding-left: 3.4rem;
      line-height: 1.44;

      svg {
        position: absolute;
        top: .25rem;
        left: .4rem;
        width: 1.5rem;
        height: 1.5rem;
        opacity: 0;
        color: var(--checkbox-border-color);
        transform: scale(.5);
        transition: opacity .25s ease-in-out, transform .25s ease-in-out;
      }

      &:before {
        content: '';
        position: absolute;
        top: -2px;
        left: 0;
        width: 2.4rem;
        height: 2.4rem;
        border: 1px solid var(--checkbox-border-color);
        background: var(--checkbox-background-color);
        transition: border .25s ease-in-out, background-color .25s ease-in-out;
      }
    }
  }

  &__element {
    &--checkbox {
      opacity: 0;
      position: absolute;
      visibility: hidden;

      &:checked + .input__checkbox-value {
        svg {
          opacity: 1;
          transform: scale(1);
        }

        &:before {
          border: 1px solid var(--checkbox-border-color-checked);
          background: var(--checkbox-background-color-checked);
        }
      }
    }
  }
}
