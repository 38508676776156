.generic-not-found {
  max-width: 60rem;
  width: 100%;
  justify-content: center;
  align-self: center;
  color: var(--generic-not-found-color);

  &__logo {
    display: block;
    width: 8rem;
    color: inherit;

    svg {
      fill: currentColor;
    }
  }

  &__title {
    font-family: 'open_sansbold', Arial, sans-serif;
    font-size: 15rem;
    margin: 0 0 4rem;
    text-align: center;
    line-height: 1;
  }

  &__subtitle {
    font-family: 'open_sansbold', Arial, sans-serif;
    font-size: 1.4rem;
    text-align: center;
    display: block;
    margin-top: 4rem;
    margin-bottom: -1rem;
    padding-left: 4.7rem;
    opacity: .4;
  }

  &__intro {
    line-height: 1.44;
  }

  &__actions {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    @media all and (max-width: 480px) {
      justify-content: center;
    }
  }

  &__action {
    display: flex;
    white-space: nowrap;
    margin: 1rem;

    &-icon {
      width: 2rem;
      min-width: 2rem;
      max-width: 2rem;
      margin-right: 2rem;
    }
  }

  &--main {
    margin: 8rem auto;

    .generic-not-found__logo {
      display: none;
    }

    .generic-not-found__intro {
      text-align: center;
    }

    .generic-not-found__actions {
      justify-content: center;
    }
  }
}
