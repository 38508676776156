.payee-modal {
  .modal__entry {
    overflow: unset;
  }
  .select__el {
    width: 100%;
    margin-top: 2rem;
    min-height: 4rem;
    margin-bottom: 2rem;
  }

  a {
    color: inherit;
    text-decoration: underline;
  }
}

.modal__footer--left {
  justify-content: flex-start;

  .change-pass-modal-footer__action:first-of-type {
    margin-right: auto;
    background: #7f2d30;
    transition: all 0.25s ease-in-out;

    &:hover {
      background: #861f23;
    }
  }
}

.acm--loader {
  position: absolute;
  text-align: center;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.create-account-request__form-error-container {
  position: relative;
  display: block;
  top: 0.5rem;
  font-size: 1.4rem;
  height: 2.5rem;
}

.create-account-request__form-error {
  position: absolute;
  top: 1rem;
  color: var(--register-form-error-message-color);
}
