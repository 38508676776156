.header-meta {
  align-items: center;
  position: relative;

  .profile-popup {
    position: absolute;
    /* postition below the button and add space for the triangle */
    top: calc(100% + 10px);
    right: 0;
    background-color: var(--profile-popup-background-color);
    color: var(--profile-popup-text-color);
    padding: var(--page-padding);
    width: 26.3rem;
    text-align: center;
    z-index: 5;

    &::before {
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      right: 1rem;
      /* would be -10px but that leaves a 1px gap */
      top: -9px;
      /* triangle shape */
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid var(--profile-popup-background-color);
    }

    &__header {
      font-size: 2rem;

      & p {
        font-size: 1.4rem;
        color: rgba(255, 255, 255, 0.5);
        margin-top: 1rem;
        margin-bottom: 0;
      }
    }

    &__links {
      margin-top: 3rem;
    }

    hr {
      display: none;
      margin: 2rem 0;
      opacity: .1;
      border: none;
      height: 1px;
      background: #fff;
    }

    &__link {
      display: block;
      width: 100%;
      border: 1px solid;
      color: inherit;
      padding: 1.5rem;
      border-radius: 3px;

      &--company-profile--disabled {
        opacity: .3;
        pointer-events: none;
      }

      &--mobile {
        display: none;
        background: #27565d;
      }

      @media all and (max-width: 480px) {
        font-size: 1.4rem;
        padding: 1.2rem;
      }

      @media all and (max-width: 900px) {
        &--mobile {
          display: block;
        }
      }
    }

    @media all and (max-width: 900px) {
      hr {
        display: block;
      }
    }

    &__link + .profile-popup__link {
      margin-top: 1rem;
    }

    &__link--logout {
      background-color: var(--profile-popup-text-color);
      color: var(--profile-popup-background-color);
    }
  }
}

.header-action {
  justify-content: center;
  align-items: center;
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  background: var(--header-actions-bg);
  color: var(--header-actions-color);
  margin-left: 1rem;
  transition: background .25s ease-in-out, color .25s ease-in-out;

  &--active,
  &:hover {
    background: var(--header-hover-actions-bg);
    color: var(--header-hover-actions-color);
  }

  &--profile {
    position: relative;

    &:hover {
      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        left: -2rem;
        bottom: -8rem;
        cursor: pointer;
      }
    }
  }

  &__settings {

  }

  &--fs {
    @media all and (max-width: 768px) {
      display: none;
    }
  }

  &--burger {
    position: relative;
    z-index: 66;
  }

  &__user-avatar {
    background: var(--header-actions-avatar-bg);
  }

  &__icon {
    position: relative;
    justify-content: center;
    align-items: center;
    height: 1.8rem;
    width: 100%;

    &-moon {
      fill: var(--moon-icon-bg);
    }

    &-sun {
      fill: var(--sun-icon-bg);
    }

    img {
      width: 100%;
      border-radius: 50%;
      max-height: 4rem;
    }

    &-animate {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0;
      visibility: hidden;
      transform: scale(.4);
      transition: transform .25s ease-in-out, opacity .25s ease-in-out, visibility .25s ease-in-out;

      &--visible {
        opacity: 1;
        visibility: visible;
        transform: scale(1);
      }
    }
  }

  svg {
    width: 100%;
    height: 100%;
  }

  &--notification {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 1.3rem;
      height: 1.3rem;
      border-radius: 50%;
      background: var(--header-actions-notification);
    }
  }

  &__avatar {
    border-radius: 50%;
    overflow: hidden;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}

.modal-reset-info {
  background: var(--header-actions-reset-info);
  padding: 1rem 2rem;
  margin: 1.5rem 0;
}

.burger-nav-action {

}
//
//@media (max-width: 576px) {
//  .header-meta {
//    margin-left: 0;
//  }
//
//  .header-meta .profile-popup {
//    left: 50%;
//    transform: translateX(-50%);
//  }
//
//  .header-meta .profile-popup::before {
//    right: 25%;
//  }
//}
