.page {
  transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out;

  &--primary {
    min-height: 100vh;
  }
}

.content {
  font-size: 2rem;
  padding: var(--page-padding);

  @media all and (max-width: 640px) {
    padding: var(--mobile-page-padding);
  }

  &.container-fluid {
    max-width: 100%;
  }

  &__entries {
    padding-top: 1rem;
  }

  &__wrapper {
    width: 100%;
    max-width: 70rem;
    margin: 0 auto;
  }

  p {
    line-height: 1.4;
  }

  &__sidebar {
    max-width: 40rem;
    min-width: 40rem;
    width: 40rem;
  }

  &__title {
    margin: 3rem 0 1.5rem;
    font-size: 3rem;
    line-height: 1.44;
    color: var(--page-header-color);
    text-align: center;

    @media (max-width: 500px) {
      font-size: 2.2rem;
    }

    &--homepage {
      text-align: left;
    }

    &--page {
      align-items: center;
      text-transform: capitalize;
      text-align: left;
      margin-bottom: 1rem;

      .inline-loader {
        margin-left: 1rem;
      }
    }
  }

  &__entry-title {
    margin-top: 0;
    margin-bottom: 2.5rem;
  }

  &__search-title {
    line-height: 1;

    .inline-loader {
      margin-left: 1rem;
      align-items: center;
    }

    strong {
      margin: 0 .5rem;
    }
  }

  &__contact-form{
    max-width: 40rem;
    min-width: 40rem;
    width: 40rem;
    padding-top: 1.2rem;
  }
}

.content__search {
  position: relative;
  width: 30rem;
  min-width: 30rem;
  max-width: 30rem;
  margin-left: auto;
  margin-top: 3rem;
  padding-left: .5rem;

  @media all and (max-width: 768px){
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    margin-left: 0;
    margin-top: 1.5rem;
    margin-bottom: 2.5rem;
    padding-left: 0;
  }

  &-actions {
    position: absolute;
    display: flex;
    right: 0;
    bottom: 2px;
    top: 0;
    height: 5rem;
  }

  &-error {
    color: var(--page-content-error);
    position: absolute;
    right: 0;
    font-size: 1.2rem;
    width: 100%;
    top: 100%;
    margin-top: .5rem;
    min-width: 30rem;
    text-align: right;
    transition: opacity .25s ease-in-out, color .25s ease-in-out, transform .25s ease-in-out;
  }

  .input__element {
    padding: 0 4.7rem 0 1rem;
    background: var(--page-content-meta-input-bg);
    border: 1px solid var(--page-content-meta-input);
    color: var(--page-content-input-color);
    transition: border .25s ease-in-out, color .25s ease-in-out, background .25s ease-in-out;

    &::placeholder {
      color: var(--page-content-input-color);
      opacity: .6;
      transition: color .25s ease-in-out;
    }

    &[disabled] {
      &::placeholder {
        color: var(--tags-color--disabled);
      }
    }

    &--error {
      border: 1px solid var(--page-content-error);
    }

    &--has-value {
      padding-right: 8.3rem;
    }
  }

  .clear-btn,
  .search-btn {
    transition: color .25s ease-in-out, opacity .25s ease-in-out;

    &--disabled {
      pointer-events: none;
      cursor: none;
      opacity: .3;
    }
  }

  .clear-btn {
    color: var(--page-content-btn-clear-color);
    padding-right: 0;
    width: 3.3rem;

    &:not(.clear-btn--disabled):hover {
      color: var(--page-content-btn-clear-color--hover);
    }
  }

  .search-btn {
    color: var(--page-content-btn-color);

    &:not(.search-btn--disabled):hover {
      color: var(--page-content-btn-color--hover);
    }
  }

  &--disabled {
    .input__element {
      pointer-events: none;
      cursor: default;
      background: var(--page-content-meta-input-bg--disabled);
      border: 1px solid var(--page-content-meta-input--disabled);
      color: var(--page-content-input-color--disabled);
    }

    .search-btn--disabled {
      color: var(--tags-color--disabled);
    }
  }
}

.content__meta {
  position: sticky;
  top: 0;
  z-index: 3;
  padding-top: 1.2rem;
  padding-bottom: 1rem;
  background: var(--page-meta-bg);
  transition: background .25s ease-in-out;
  transform: translateZ(0);

  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 100%;
    box-shadow: 0 3px 4px rgba(0, 0, 0, .1);
    transform: translateX(-50%);
    background: var(--sticky-header-bg);
    transition: opacity .25s ease-in-out, visibility .25s ease-in-out, background-color .25s ease-in-out;
  }

  &--sticky {
    background: var(--page-meta-bg-sticky);

    &:after {
      opacity: 1;
      visibility: visible;
    }
  }

  &--loading {
    position: relative;
  }

  &-container {
    position: relative;
  }
}

.content__observer-el {
  height: 1px;
}

.content__search-error-animation {
  &-enter {
    transform: translateX(3rem);
    opacity: 0;
  }

  &-enter-active,
  &-enter-done {
    transform: translateX(0);
    opacity: 1;
  }

  &-exit {
    transform: translateX(0);
    opacity: 1;
  }

  &-exit-active {
    transform: translateX(-3rem);
    opacity: 0;
  }
}

.header-page {
  margin-top: 3rem;

  @media all and (max-width: 768px){
    flex-wrap: wrap;
  }
}

.header-page__breadcrumbs {
  a {
    display: block;
  }

  .home-nav__description {
    padding: .5rem 0;
    color: var(--subtitle-color);
    transition: color .25s ease-in-out, padding .25s ease-in-out;

    &:hover {
      color: var(--subtitle-color-hover);
      padding: .5rem;
    }

    svg {
      width: 2rem;
      margin-right: 1rem;
    }
  }
}

.search {
  display: flex;
  flex-wrap: wrap;

  &__header {
    margin: 2rem 2rem 2rem 0;
    font-size: 2rem;
    color: var(--search-header-color);
  }

  &__clear {
    display: flex;
    font-size: 1.4rem;
    align-items: center;
    line-height: 1;
    color: var(--page-content-btn-clear-color);

    svg {
      width: 1.4rem;
      margin-right: .5rem;
    }
  }
}
