.password-strength {
  &__bar {
    display: flex;
    gap: 1rem;
  }

  &__title {
    display: block;
    text-transform: uppercase;
    opacity: .4;
    font-size: 1rem;
    margin-bottom: .75rem;
  }

  &__item {
    width: 100%;
    height: .8rem;
    background-color: var(--pass-strength-item);
    font-size: 1rem;
    text-transform: uppercase;
    font-family: "open_sansbold", Arial, sans-serif;
    line-height: 3;
    opacity: .4;
    transition: background-color .25s ease-in-out, opacity .25s ease-in-out;

    &--too-weak {
      background-color: var(--pass-strength-item-too-weak);
      opacity: .7;
    }

    &--weak {
      background-color: var(--pass-strength-item-weak);
      opacity: .7;
    }

    &--medium {
      background-color: var(--pass-strength-item-medium);
      opacity: .7;
    }

    &--strong {
      background-color: var(--pass-strength-item-strong);
      opacity: .7;
    }
  }
}
