.d-flex {
  display: flex;
}

.f-wrap {
  flex-wrap: wrap;
}

.f-column {
  flex-direction: column;
}

.justify {
  &-content-space-between {
    justify-content: space-between;
  }
  &-content-center {
    justify-content: center;
  }
  &-content-flex-end {
    justify-content: flex-end;
  }
}

.align-items-center {
  align-items: center;
}

.overflow-auto {
  overflow: auto;
}

.overflow--hidden {
  overflow: hidden;
}

.position-relative {
  position: relative;
}

.visually-hidden {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important; // Fix for https://github.com/twbs/bootstrap/issues/25686
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.text-align-right {
  text-align: right;
}

.text-align-left {
  text-align: left;
}

@media (max-width: 992px) {
  .f-column-md {
    flex-direction: column;
  }

  .mt-2-md {
    margin-top: 2rem;
  }

  .mt-3-md {
    margin-top: 3rem;
  }
}

@media (max-width: 768px) {
  .f-column-sm {
    flex-direction: column;
  }

  .mt-2-sm {
    margin-top: 2rem;
  }

  .mt-3-sm {
    margin-top: 3rem;
  }
}

@media (max-width: 576px) {
  .f-column-xs {
    flex-direction: column;
  }

  .mt-2-xs {
    margin-top: 2rem;
  }

  .mt-3-xs {
    margin-top: 3rem;
  }

  .w-100-xs {
    width: 100%;
  }
}
