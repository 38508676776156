.add-user-modal {
  .modal__content {
    width: 65rem;
  }

  .create-account-request__form-error-container {
    position: relative;
    display: block;
    top: .5rem;
    font-size: 1.4rem;
    height: 2.5rem;
  }

  .create-account-request__form-error {
    position: absolute;
    top: 1rem;
    color: var(--register-form-error-message-color);
  }

  .input + .input {
    margin-top: 2rem;
  }

  .input {
    &__element {
      color: var(--register-form-color);
      background: var(--register-form-bg-color);
      transition: box-shadow .25s ease-in-out;

      &--error {
        box-shadow: inset 0 0 0 3px var(--register-form-error-border);
      }

      &--disabled {
        color: var(--register-form-color-disabled);
        background: var(--register-form-bg-color-disabled);
      }
    }
  }

  .change-pass-modal-footer {
    .inline-loader {
      display: flex;
      align-items: center;
      margin-right: 1rem;
    }

    &__action {
      transition: all .25s ease-in-out;

      &--disabled {
        opacity: .6;
        pointer-events: none;
      }
    }
  }
}

.ms-account {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;

  .ms-checkbox {
    margin-left: auto;

    .suggestions {
      top: 9rem;
    }
  }

  .suggestions {
    top: 9rem;
    max-height: 20rem;
    overflow: auto;

    &__item {
      width: 20rem;
    }
  }

  .matter-number {
    opacity: 0;
    overflow: hidden;
    max-height: 0;
    transition: all .25s ease-in-out;

    &--visible {
      opacity: 1;
      max-height: 10rem;
    }
  }

  .matter-number .matter-number--actions {
    top: 1.6rem;
  }
}
