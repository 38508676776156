.page[data-title="Home"] {
  text-align: center;
}

.content--homepage {
  &.container-fluid {
    max-width: 100%;
  }
}

.content__wrapper--homepage {
  text-align: left;
  p {
    font-size: 1.6rem;
  }

  .btn {
    font-size: 1.4rem;
  }
}

.no-payees-available {
  position: relative;

  small {
    opacity: 0.8;
    display: block;
    margin-top: 2rem;
    font-size: 1rem;
    line-height: 1.44;
  }

  &--action {
    opacity: 1;
    transition: opacity 0.25s ease-in-out, background-color 0.25s ease-in-out;

    &[disabled] {
      opacity: 0.6;
      pointer-events: none;
    }
  }

  &--separator {
    position: relative;
    left: 11rem;
    display: inline-block;
    margin: 1.5rem auto;
    text-transform: uppercase;
    font-size: 1.1rem;
    line-height: 1;
    opacity: 0.2;

    &:before,
    &:after {
      content: "";
      position: absolute;
      width: 10rem;
      top: 50%;
      transform: translateY(-50%);
      height: 1px;
      background: currentColor;
    }

    &:before {
      left: -11rem;
    }

    &:after {
      right: -11rem;
    }
  }

  &__action {
    display: flex;
    justify-items: center;
    align-items: center;
    margin: 2.6rem 0 0;

    svg {
      width: 1.5rem;
      margin-left: 1rem;
    }
  }

  &--loader {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
  }
}

.payee {
  display: flex;
  align-items: center;
  font-size: 1.4rem;

  .select__el {
    min-height: 4rem;
  }

  .gl-ref-select {
    margin-bottom: 0;
  }

  &__save {
    display: flex;
    white-space: nowrap;
    opacity: 1;
    transition: background-color 0.25s ease-in-out, opacity 0.25s ease-in-out;

    &[disabled] {
      opacity: 0.6;
      pointer-events: none;
    }

    svg {
      width: 1.4rem;
      margin-left: 1rem;
    }
  }
}

.home-nav {
  max-width: 90rem;
  margin-top: 2.5rem;
  margin-left: auto;
  margin-right: auto;

  li + li {
    margin-top: 1rem;
  }

  &__link {
    display: block;
    padding: 1.5rem;
    color: var(--sidebar-item-color);
    background: var(--sidebar-bg-color);
    border-bottom: 2px solid var(--sidebar-border-item-color);
    text-align: center;
    transition: color 0.25s ease-in-out, background 0.25s ease-in-out,
      padding 0.25s ease-in-out, border 0.25s ease-in-out;

    &:hover {
      padding-left: 3rem;
      color: var(--sidebar-item-color-hover);
      background: var(--sidebar-bg-color-hover);
    }
  }
}

.home-cards {
  justify-content: center;

  &--notes {
    width: 100%;
    max-width: 60rem;
    text-align: center;
    margin: 2rem auto;
    line-height: 1.44;

    a {
      color: var(--home-link-color);
      text-decoration: underline;
    }
  }

  &__link {
    justify-content: center;
    align-items: center;
    width: 30rem;
    max-width: 100%;
    font-family: "open_sansbold", Arial, sans-serif;
    min-height: 12rem;
    font-size: 1.8rem;
    margin: 1.5rem;
    background: var(--home-card-bg);
    color: var(--home-card-color);
    border: 1px solid var(--home-card-border);
    transition: background 0.25s ease-in-out, color 0.25s ease-in-out,
      border 0.25s ease-in-out;

    svg {
      width: 2.4rem;
      margin-left: 1rem;
    }

    &:hover {
      background: var(--home-card-bg-hover);
      color: var(--home-card-color-hover);
      border: 1px solid var(--home-card-border-hover);
    }
  }
}

.homepage-loader {
  display: flex;
  justify-content: center;
  margin-top: 4rem;
  text-align: center;
}

.homepage-selections {
  display: flex;
  align-items: flex-start;
  gap: 2rem;
  flex-wrap: wrap;

  .select__el {
    font-size: 1.6rem;
    min-height: 4rem;
  }

  @media all and (max-width: 700px) {
    .select {
      width: 100%;
    }

    .select__el {
      width: 35rem;
      max-width: 100%;
    }
  }
}

.quick-links {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding-top: 3.5rem;
  margin-top: 2rem;
  opacity: 0;
  transform: translateX(-1rem);
  visibility: hidden;
  transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out,
    visibility 0.25s ease-in-out;

  &--active {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 5rem;
    border-top: 1px solid rgba(151, 176, 176, 0.15);
  }

  &:before {
    content: attr(data-title);
    position: absolute;
    letter-spacing: 1px;
    font-family: "open_sanssemibold", Arial, sans-serif;
    top: 2rem;
    font-size: 1rem;
    text-transform: uppercase;
  }

  &__actions {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 2rem;
    padding-top: 1rem;

    & + &__actions {
    }
    margin-top: 1rem;
  }

  &__action {
    padding: 1rem 1.4rem;
    font-size: 1.6rem;
    color: #fff;
    background: #27555c;
    border-radius: 1rem;
    border: 2px solid rgba(255, 255, 255, 0.05);
  }
}

.searchBox {
  width: 90% !important;
}

.searchBoxFont {
  font-family: "open_sansregular", Arial, sans-serif;
  font-size: 1.6rem;
}

.text-wrap {
  text-wrap: pretty;
}
