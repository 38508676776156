.row {
  &__entry {
    width: 100%;
    display: flex;
    gap: 2rem;
  }

  &__item {
    width: 100%;

    .input-upload__heading {
      color: #fff;
    }
  }
}

.invoice-input {
  position: relative;

  .input__element {
    min-height: 4rem;
    transition: all 0.25s ease-in-out;
    box-shadow: inset 0 0 0 3px rgba(0, 0, 0, 0);
  }

  .textArea__element {
    min-height: 20rem;
    background: var(--input-bg-color-alt);
    box-shadow: inset 0 0 0 3px rgba(255, 255, 255, 0);
    border-radius: 3px;
    line-height: 1.44;
  }

  &--error {
    .textArea__element {
      box-shadow: inset 0 0 0 3px var(--register-form-error-border);
    }
  }

  .input__title {
    color: #fff;
  }

  .input__inline-error {
    font-size: 1.4rem;
    margin-top: 0.3rem;
  }

  .input__element--error {
    box-shadow: inset 0 0 0 3px var(--register-form-error-border);
  }
}

.invoice-select {
  .select__el {
    min-height: 4rem;
    width: 100%;
  }

  &-item {
    display: flex;

    .input__inline-error {
      font-size: 1.2rem;
      margin-top: 0.3rem;
    }
  }
}

.invoice-totals {
  display: flex;
  justify-content: space-between;
  grid-gap: 6rem;
  gap: 6rem;
  margin-top: auto;
  border-top: 1px dashed rgba(168, 181, 192, 0.35);
  padding-top: 3rem;
  padding-bottom: 1rem;

  &--totals,
  &--expected {
    width: 100%;
  }

  &--expected {
    width: 40%;
  }

  &--totals {
    display: flex;
    width: 60%;
    gap: 2rem;
  }
}

.invoice-row__remove {
  position: relative;
  top: -2px;
  display: flex;
  align-items: center;
  line-height: 1;
  margin-right: 1.2rem;
  color: var(--btn-error-bg-alt);
  font-family: open_sanssemibold, Arial, sans-serif;

  svg {
    width: 2.1rem;
    fill: currentColor;
  }
}

.invoice-action {
  padding: 1rem 1.2rem;
  font-size: 1rem;
  text-transform: uppercase;
  font-family: "open_sanssemibold", Arial, sans-serif;
  letter-spacing: 0.75px;

  &--disabled {
    opacity: 0.6;
    cursor: auto;
  }
}

.invoice-actions {
  display: flex;
  align-items: center;
  position: absolute;
  top: 2.3rem;
  right: 0.4rem;
  gap: 1rem;
}

.invoice-datepicker {
  .MuiButtonBase-root {
    z-index: 1;
    margin-left: 1rem;
    margin-right: 0.5rem;
  }

  .MuiButtonBase-root:after {
    content: "";
    position: absolute;
    left: -10rem;
    top: -10rem;
    bottom: -10rem;
    right: -100rem;
    z-index: -1;
  }

  .MuiButtonBase-root svg {
    width: 2rem;
    height: 2rem;
  }

  .MuiFormControl-root {
    display: block;
    width: 100%;
  }

  .MuiOutlinedInput-root {
    overflow: hidden;
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    width: 100%;
    border-radius: 3px;
    margin-top: 0;
    flex-direction: row-reverse;
    background: #fff;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
  }

  input.MuiOutlinedInput-input {
    color: inherit;
    margin-top: 0;
    height: 4rem;
    padding: 0;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
}

.invoice__item {
  position: relative;
  display: flex;
  outline: none;
  color: #fff;
  border: none;
  justify-content: center;
  align-items: center;
  background: #004d90;
  padding: 7px 13px;
  font-size: 1.2rem;
  line-height: 1;
  border-radius: 5px;
  cursor: pointer;
  margin: 2rem 1rem 0;

  &--no-invoice {
    cursor: auto;
    background: none;
    text-transform: uppercase;
    font-size: 1rem;
    color: var(--btn-error-bg);
    line-height: 1.44;
    margin: 24px 0 0;

    &-docs {
      color: #fff;
    }
  }

  &-name {
    white-space: nowrap;
    max-width: 10rem;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 0.75rem;
    line-height: 1.44;
  }

  svg {
    width: 2rem;
  }

  &-close {
    position: absolute;
    white-space: nowrap;
    width: 1.8rem;
    background: var(--btn-error-bg);
    height: 1.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    right: -8px;
    top: -10px;
    border-radius: 50%;

    & > svg {
      width: 0.9rem;
    }
  }
}

.invoice__items {
  display: flex;
  flex-wrap: wrap;

  &-wrapper {
    margin: 0 -1rem;
    padding: 0.4rem 1rem 0 2rem;
  }
}

.invoice-section {
  position: relative;
  overflow: hidden;
  padding-top: 1rem;
  max-height: 5rem;
  transition: max-height 0.25s ease-in-out, flex 0.25s ease-in-out;

  &--collapsed {
    max-height: 100rem;
  }

  &--amount {
    display: flex;
    flex-direction: column;
    flex: 0;

    &.invoice-section--collapsed {
      flex: 1;
    }
  }

  &:before {
    content: attr(data-title);
    display: block;
    margin-bottom: 2.7rem;
    padding-bottom: 1rem;
    font-size: 1.8rem;
    font-family: "open_sanssemibold", Arial, sans-serif;
    border-bottom: 1px solid var(--invoice-overlay--title-border);
    transition: padding-left 0.25s ease-in-out;
    color: #fff;
  }

  &--error-section {
    &:before {
      padding-left: 3rem;
    }
  }

  & + & {
    margin-top: 3rem;
  }

  .invoice-entry--action {
    bottom: auto;
    top: 0;
  }
}

.invoice-new-row {
  position: absolute;
  display: flex;
  align-items: center;
  bottom: 100%;
  margin-bottom: 1.5rem;
  font-family: open_sanssemibold, Arial, sans-serif;
  color: #fff;
  font-size: 1.4rem;
  opacity: 1;
  transition: color 0.25s ease-in-out, opacity 0.25s ease-in-out;

  svg {
    width: 2.4rem;
    color: var(--notification-success-bg);
    margin-right: 0.5rem;
  }

  &[disabled] {
    opacity: 0.4;
    pointer-events: none;
    cursor: auto;
  }
}

.invoice-rows {
  position: relative;
  display: flex;
  gap: 2rem;
  margin-top: 2.7rem;
}

.invoice-col {
  width: 100%;
}

.invoice-section--error {
  position: absolute;
  left: 0;
  top: 1.1rem;
  display: flex;
  width: 2rem;
  color: var(--generic-error-color-lighter);
  transform: scale(0.4);
  opacity: 0;
  visibility: hidden;
  transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out,
    visibility 0.25s ease-in-out;

  &-active {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
  }

  svg {
    fill: currentColor;
  }
}

.invoice-entry-actions {
  padding-top: 2rem;
  margin-top: auto;
  gap: 2rem;
  left: 0;
  right: 0;
  max-width: 100.5rem;
  display: flex;
  justify-content: flex-end;

  .btn--reset {
    color: #fff;
  }

  &--save {
    background-color: #4079ac;

    &[disabled] {
      opacity: 0.6;
      cursor: auto;
      pointer-events: none;
    }

    &:hover:not([disabled]) {
      background-color: #326491;
    }
  }
}

.suggestions {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 2;
  top: 100%;
  margin-top: 2px;
  background-color: var(--generic-suggestion-bg-color);

  &--overhead {
    max-height: 18rem;
    overflow: auto;
  }

  &__clear-action {
    position: absolute;
    display: flex;
    align-items: center;
    top: 1.8rem;
    bottom: 0;
    right: 1rem;
  }

  &__clear.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    font-size: 1rem;
    text-transform: uppercase;
    font-family: "open_sanssemibold", Arial, sans-serif;
    letter-spacing: 0.5px;
    right: 10px;
    padding: 0.7rem 0.9rem;
    height: auto;

    svg {
      width: 1.4rem;
    }
  }

  &__item {
    color: #fff;
    background-color: var(--generic-suggestion-bg-color);
    border: none;
    width: 15rem;
    font-size: 1.2rem;
    font-family: "open_sanssemibold", Arial, sans-serif;
    cursor: pointer;
    text-align: left;
    border-radius: 0;
    padding: 1.3rem 2rem;
    transition: background-color 0.25s ease-in-out;

    &:hover {
      background-color: var(--button-notify-bg);
    }

    & + & {
      margin-top: 1px;
    }

    &-entry {
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  & + .input__inline-error {
    padding-left: 16rem;
  }
}

.input__element--error ~ .suggestions {
  margin-top: -2.3rem;
}

.invoice-select__container {
  width: 100%;

  .input__title {
    color: #fff;
  }
}

.invoice-input--actions {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0.4rem;
  gap: 0.5rem;
  top: 2.3rem;

  &-btn {
    font-size: 1rem;
    font-family: open_sanssemibold, Arial, sans-serif;
    text-transform: uppercase;
    padding: 1rem 1.2rem;
    background-color: #4079ac;
    cursor: pointer;

    &[disabled] {
      opacity: 0.6;
      pointer-events: none;
      cursor: auto;
    }

    &:hover:not([disabled]) {
      background-color: #306391;
    }
  }
}

.marginTop {
  margin-top: 2.7rem;
}

.marginTopOverhead {
  margin-top: 3.8rem;
}

.marginTopOverheadSecondary {
  margin-top: 2rem;
}
