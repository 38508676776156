.drop-down-container {
  position: relative;
  width: 40rem;
  height: 4rem;

  @media (max-width: 700px) {
    width: 35rem;
    max-width: 100%;
  }

  .drop-down-clickable {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 0 0 2rem;
    cursor: pointer;
    border-radius: 3px;
    background: var(--base-bg);
    border: 1px solid var(--page-content-meta-input);
    color: var(--page-content-input-color);

    &__icon-up {
      width: 3rem;
      height: 4rem;
      padding-right: 2rem;
      cursor: pointer;
      color: var(--page-content-input-color);

      svg {
        fill: currentColor;
        width: 10px;
        transition: all 0.5s ease-in-out;
        transform: rotate(-90deg);
      }
    }

    &__icon-down {
      width: 3rem;
      height: 4rem;
      padding-right: 2rem;
      cursor: pointer;
      color: var(--page-content-input-color);

      svg {
        fill: currentColor;
        width: 10px;
        transition: all 0.5s ease-in-out;
        transform: rotate(90deg);
      }
    }

    &__clear-all {
      color: var(--btn-error-bg);
      padding: 0 1.5rem;
      height: 4rem;

      svg {
        width: 15px;
        color: currentColor;
      }

      &:hover {
        color: #333;
      }
    }

    &__select-company {
      overflow: hidden;
      color: var(--page-content-input-color);
      width: 100%;
      height: 4rem;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .drop-down-list {
    display: block;
    position: absolute;
    top: 4.2rem;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    margin: 0 auto;
    height: auto;
    max-height: 45rem;
    overflow: auto;
    background: var(--modal-content-bg);
    border: 1px solid var(--page-content-meta-input);
    border-top: 0;
    margin-top: -1px;
    z-index: 20;
    opacity: 0;
    transition: all 300ms ease-in-out;

    ul {
      padding: 0 5px;
      margin-top: 0;
    }

    &__expand {

      opacity: 1;
    }
  }

  .empty-results {
    padding: 1rem;
    color: #4079ad;
  }
}