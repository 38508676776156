.companies {
  min-height: calc(100vh - 23rem);

  .users__table {
    min-width: 100%;
  }

  &--loading .users__title {
    margin-bottom: 5rem;
  }

  .add-company-btn {
    height: 4rem;

    @media all and (max-width: 500px) {
      position: absolute;
      bottom: 100%;
      margin-bottom: 2rem;
      right: 0;
    }

    &[disabled] {
      transition: all .25s ease-in-out;
      opacity: .6;
    }

    svg {
      @media all and (max-width: 640px) {
        margin-right: 0 !important;
      }
    }

    &__content {
      @media all and (max-width: 640px) {
        display: none;
      }
    }
  }

  .add-payee {
    margin-left: 0;
    transition: all .25s ease-in-out;

    &[disabled] {
      opacity: .6;
      pointer-events: none;
    }
  }

  .acm-wrapper {
    @media all and (max-width: 500px) {
      flex-wrap: wrap;
      width: 100%;

      .select {
        margin-bottom: 2rem;
      }

      .select,
      .select__el {
        width: 100%;
      }
    }

    .select__el {
      min-height: 4rem;
    }

    .users__action {
      height: 4rem;

      &:first-of-type {
        margin-left: 2rem;

        @media all and (max-width: 500px) {
          margin-left: auto;
        }
      }
    }
  }
}

.payees__entries {
  .inline-loader {
    margin-top: 5rem;
  }
}

.payees__table-vendor-cell {
  width: 24rem;
}

.payees__table-edit svg {
  transform: none;
}

.payees__table {
  .users__tbody {
    .users__entry {
      @media all and (max-width: 640px) {
        font-size: 1.4rem;
      }
    }
  }
}

.checked-input {
  position: absolute;

  svg {
    width: 2rem;
  }
}

.payees__table {
  .users__thead .users__entry {
    color: #fff;
  }
}

.company-multiselect-options {
  margin: 1rem 0;

  .input__title {
    display: block;
  }

  .multi-select__trigger {
    color: var(--register-form-color);
    background: var(--register-form-bg-color);
    border-radius: 3px;
  }

  .history-multiselect {
    width: 100%;
    box-shadow: 0 0 0 1px rgb(0 0 0 / 5%);
    border: none;
  }

  .multi-select__option:not(.multi-select__option--selected):not(:hover) {
    background: var(--register-form-bg-color);
    color: var(--register-form-color);
  }

  .multi-select__options {
    top: 5rem;
    border: 1px solid var(--company-dropdown-multiselect-border-color-option);
    border-top: none;
  }
}

[data-theme="dark"] {
  .company-multiselect-options .multi-select__options {
    border: none;
  }
}

.company-modal .modal__entry {
  overflow: inherit;
}

.user-list {
    td {
      padding: 1rem 0;
    }
    td:not([colspan="8"]):first-of-type:before{
      border: none;
    }
    
    .modal-hr {
      margin-left: 0;
      width: 80%;
    }

    .user-highlight {
      font-family: 'open_sanssemibold';
    }

    .users-table-data { 
      padding: 1.5rem 1.5rem 0 0;
      max-width:40rem;
      }
  }

  .users-table-head {
    font-family: 'open_sanssemibold';
    font-size: larger;
    text-align: left;
    color: var(--nav-header-fs-hover-color);
  }

  .users-table {
    max-height: 20rem;
    overflow: auto;
  }

.users-modal-footer {
  justify-content: space-between;
}

.users-modal-number {
  align-self: center;
}

.users-modal-container {
  max-height: 24rem;
}