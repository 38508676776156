.header-navigation {
  margin-left: 3rem;

  @media all and (max-width: 900px) {
    display: none;
  }

  a {
    display: block;
    padding: 3.3rem 2rem;
    position: relative;
    opacity: 1;
    transition: opacity .25s ease-in-out, color .25s ease-in-out;

    .header-navigation__description {
      color: var(--header-link-color);
      transition: opacity .25s ease-in-out, color .25s ease-in-out;
    }

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      margin-top: 2rem;
      left: 50%;
      transform: translateX(-50%) scale(0);
      height: 2px;
      width: 3rem;
      background: rgba(255, 255, 255, 0);
      transition: background-color .25s ease-in-out, transform .25s ease-in-out;
    }

    &.active {
      color: var(--header-link-active-color);

      .header-navigation__description {
        color: var(--header-link-item-color);
      }

      &:after {
        background: var(--nav-link-border);
        transform: translateX(-50%) scale(1);
      }
    }

    &:hover {
      .header-navigation__description {
        color: var(--header-link-color-hover);
      }
    }
  }
}

@media (max-width: 576px) {
  .header-navigation li {
    margin-left: 2rem;
  }
  .header-navigation li:first-child {
    margin-left: 0;
  }
}
