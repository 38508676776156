.history-datepicker {
  display: flex;
  flex-direction: column;
  padding: 0;
  min-width: 25rem;

  .invoice-datepicker input.MuiOutlinedInput-input {
    height: 5rem;
  }

  & + & {
    margin-left: 1.5rem;

    @media all and (max-width: 1300px) {
      margin-left: 2rem;
    }

    @media all and (max-width: 500px) {
      margin-left: 0;
      margin-top: 2rem;
    }
  }

  @media all and (max-width: 1024px) {
    min-width: 25rem;
  }

  @media all and (max-width: 640px) {
    min-width: 1%;
    max-width: 100%;
    width: 100%;
  }
}

.history__dates-form {
  position: relative;
  display: flex;
  margin-right: 2.5rem;
  padding-right: 2.5rem;

  @media all and (max-width: 1300px) {
    margin-right: 0;
    padding-right: 0;
  }

  @media all and (max-width: 640px) {
    width: 100%;
  }

  @media all and (max-width: 500px) {
    flex-wrap: wrap;
  }

  &:after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 1rem;
    width: 1px;
    height: 3rem;
    background-color: rgba(128, 128, 128, 0.3);

    @media all and (max-width: 1300px) {
      display: none;
    }
  }
}
