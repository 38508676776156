.set-pass {
  .input__inline-error {
    font-size: 1.2rem;
    margin-top: .3rem;
  }

  .register-form__list {
    font-size: 1.4rem;
    margin-bottom: 3rem;
  }

  .register-form__intro {
    margin-top: 3rem;
    line-height: 1.44;
  }

  .sign-in__show-pass-btn {
    position: absolute;
    width: 5rem;
    height: calc(100% - 2rem);
    right: 0;
    padding: 0 1.5rem;
    opacity: .5;
    transition: opacity .25s ease-in-out;

    &:hover {
      opacity: .7;
    }

    svg {
      fill: currentColor;
    }
  }

  .input {
    position: relative;
  }

  .input + .input {
    margin-bottom: .5rem;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 5rem;

    &--multiple {
      justify-content: space-between;
    }
  }
}
