.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 49;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--modal-color);
  cursor: auto;
  font-size: 1.6rem;
  font-weight: normal;
  transition: all 0.45s ease-in-out;

  &__mask {
    position: fixed;
    z-index: -1;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    background: var(--modal-overlay-bg);
    opacity: 0;
    transition: opacity 0.45s ease-in-out;
  }

  &__content {
    max-width: 100%;
    width: 45rem;
    margin: 1rem;
    transform: translateY(5rem);
    opacity: 0;
    transition: transform 0.45s ease-in-out, opacity 0.45s ease-in-out;
  }

  &__header {
    position: relative;
    display: flex;
    padding: 0 2rem;
    min-height: 5rem;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background: var(--modal-header-default-bg);
    color: var(--modal-header-color);
  }

  &__close {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
    top: 0;
    height: 5.2rem;
    width: 4.5rem;
    padding: 2px 1.8rem 0 1.1rem;
    color: var(--modal-close-icon-color);
    transition: color 0.25s ease-in-out;

    svg {
      width: 100%;
    }

    &:hover {
      color: var(--modal-close-icon-hover-color);
    }
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.2rem;
    height: 5rem;
    margin-right: 1rem;

    svg {
      width: 100%;
    }
  }

  &__title {
    padding: 1.5rem 0;
    margin: 0;
    font-size: 2rem;
    line-height: 1;
    font-weight: normal;
    font-family: "open_sanssemibold", arial, SansSerif, serif;
  }

  &__entry {
    padding: 2rem 2.2rem 1.5rem;
    background: var(--modal-content-bg);
    max-height: 80vh;
    overflow: auto;
  }

  p {
    margin: 0;
    line-height: 1.44;
  }

  p + p {
    margin-top: 1rem;
  }

  &__footer {
    padding: 0 2.2rem 2rem;
    justify-content: flex-end;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background: var(--modal-content-bg);

    @media all and (max-width: 480px) {
      font-size: 1.4rem;
    }

    &-action {
      & + & {
        margin-left: 1rem;
      }
    }
  }
}

.modal .modal-buttons--continue[disabled] {
  opacity: 0.6;
  pointer-events: none;
}

.modal--error {
  .modal__header {
    background: var(--modal-header-error-bg);
  }

  .modal-buttons--continue {
    background: var(--button-error-bg);

    &:hover {
      background: var(--button-error-bg-hover);
    }
  }
}

.modal--default {
  .modal__header {
    background: var(--modal-header-default-bg);
  }

  .modal-buttons--continue {
    background: var(--modal-header-default-bg);

    &:hover {
      background: var(--modal-header-default-bg-hover);
    }
  }
}

.modal--notify {
  .modal__header {
    background: var(--modal-header-notify-bg);
  }

  .modal-buttons--continue {
    background: var(--button-notify-bg);

    &:hover {
      background: var(--button-notify-bg-hover);
    }
  }
}

.animate-modal {
  &-enter {
    .modal__mask {
      opacity: 0;
    }

    .modal__content {
      transform: translateY(5rem);
      opacity: 0;
    }
  }

  &-enter-active,
  &-enter-done {
    .modal__mask {
      opacity: 1;
    }

    .modal__content {
      transform: translateY(0);
      opacity: 1;
    }
  }

  &-exit {
    .modal__mask {
      opacity: 1;
    }

    .modal__content {
      transform: translateY(0);
      opacity: 1;
    }
  }

  &-exit-active {
    .modal__mask {
      opacity: 0;
    }

    .modal__content {
      transform: translateY(-5rem);
      opacity: 0;
    }
  }
}
